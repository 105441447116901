


export const saveReduxState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    sessionStorage.setItem('state--', serializedState)
  } catch (err) {
    //
  }
}


export const loadReduxState = () => {
  try {
    const serializedState = sessionStorage.getItem('state--')
    if(!serializedState) {
      return undefined
    }
    let state = JSON.parse(serializedState)
    // state.newRequest = {
    //   ...state.newRequest,
    //   selectedClients: undefined
    // }
    // state.history = {
    //   ...state.history,
    //   signatureList: undefined
    // }
    state.auth = {
      ...state.auth,
      error: undefined,
      AUTH: {},
    }
    state.newRequest = {
      ...state.newRequest,
      templates: undefined,
      clientSearchResults: undefined,
      
    }
    // state.history
    return state
    // return JSON.parse(serializedState)
  }catch(err) {
    //print err
    console.log('loadState.error:: ', err)
    return undefined
  }
}