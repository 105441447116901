import React, { useEffect,useState } from 'react'
import { Card,CardHeader,CardBody,Button,FormInput,ButtonGroup,Form,FormFeedback } from 'shards-react'
import EmailValidator from 'email-validator'
import ReactTable from 'react-table-6'
import styled from 'styled-components'
import ReactLoading from 'react-loading'
import Moment from 'react-moment'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

import HistoryTable from './viewHistoryReactTable'

import url from '../../connection/url'

import { 
  getSignatureList, 
  cancelIncompleteSignatureRequest, 
  updateCustomerEmail,  
} from '../../redux/actions/historyActions'
import { getTemplateList } from '../../redux/actions/newRequestActions'

import { connect } from 'react-redux'

const mapStateToProps = (state) => ({
  signatureList: state.history.signatureList,
  error: state.history.error,
  loading: state.history.loading,
  showRequest: state.layout.showRequest,
  cancel: state.history.cancel,
  update: state.history.update,
  updateParams: state.history.updateParams,
  params: state.history.params,
  templateFilters: state.newRequest.templates
})

const mapDispatchToProps = (dispatch) => ({
  getSigList: (ID) => dispatch(getSignatureList(ID)),
  cancelSig: (id) => dispatch(cancelIncompleteSignatureRequest(id)),
  updateEmail: (ID) => dispatch(updateCustomerEmail(ID)),
  getFilters: () => dispatch(getTemplateList())
})

export default connect(mapStateToProps,mapDispatchToProps)
(({updateParams,updateEmail,getSigList,cancelSig,signatureList,error,loading,showRequest,cancel,update,params,templateFilters,getFilters}) => {

  // HOOK FOR CANCEL REQUEST MODAL
  const [showModal,setShowModal] = useState({id:0,show:false})

  // HOOKS USED FOR EMAIL UPDATE MODAL
  const [showEmailModal,setShowEmailModal] = useState({show:false})
  const [blur,setBlur] = useState(false)
  const [emailValue,setEmailValue] = useState(null)
  const [validEmail,setValidEmail] = useState(false)
  const [originalEmail,setOriginalEmail] = useState(null)

  // HOOK USED TO REATTEMPT TO REQUEST SIGNATURE LIST IF IT FAILS
  const [attempts,setAttempts] = useState(0)
  const max_attempts = 2

  const callAttempts = () => {
    getSigList(params)
  }

  useEffect(() => {
    if((!error && !loading && !showRequest) || cancel || update) {
      if(cancel || update)  {
        // console.log('cancel or update if')
        setTimeout(() => {
          getSigList(params)
          getFilters()
        },1000)
      }
      else if(updateParams) {
        // console.log('updateParams if')
        getSigList(params)
      }else if(!signatureList) {
        // console.log('else if')
        setTimeout(() => {
            getSigList(params)
            getFilters()
          }, 1500)
      }
    }else if(error && attempts < max_attempts) {
      setTimeout(callAttempts, 1500)
      setAttempts(attempts + 1)
    }

    // if(emailValue !== null && originalEmail) 
    //   setValidEmail(testEmail(emailValue) && (emailValue !== originalEmail))

  },[showRequest,cancel,update,updateParams,attempts])

  const Styled_span = styled.span`
    overflow-y: scroll;
  `

  const updateRqsEmail = () => {
    if(emailValue) {
      setValidEmail(testEmail(emailValue))
      if(validEmail && showEmailModal.item) {
        updateEmail({
          Id: showEmailModal.item.metadata.account_id,
          customerEmail: emailValue || showEmailModal.item.signatures[0].signer_email_address,
          sig_req_id: showEmailModal.item.signature_request_id,
          sig_id: showEmailModal.item.signatures[0].signature_id
        })
        setShowEmailModal({show:false})
        setOriginalEmail(null)
        setBlur(false)
      }
    }
  }

  const testEmail = (email) => {
    return EmailValidator.validate(email)
  }

  const columns = [{
    Header: props => <span style={{fontSize: '16px'}}>Customer Company</span>,
    maxWidth: 350,
    accessor: 'company_name',
    Cell: row => (<div className="text-nowrap h-100 d-flex justify-content-start align-items-center" style={{overflowX: 'auto', wordWrap: 'none'}}>
        <span style={{fontSize: '16px'}}>{row.row.original.metadata.company_name}</span>
      </div> )
  }, 
  {
    Header: props => <span style={{fontSize: '16px'}}>Customer Name</span>,
    maxWidth: 225,
    accessor: 'customer_name',
    Cell: row => ( <div className="text-nowrap h-100 d-flex justify-content-start align-items-center" style={{overflowX: 'auto', wordWrap: 'none'}}>
        <span style={{fontSize: '16px'}}>{row.row.original.signatures && row.row.original.signatures[0].signer_name}</span>
      </div> )
  },
  {
    Header: props => <span style={{fontSize: '16px'}}>Customer Email</span>,
    maxWidth: 275,
    accessor: 'email',
    Cell: row => ( <div className="text-nowrap h-100 d-flex justify-content-start align-items-center" style={{overflowX: 'auto', wordWrap: 'none'}}>
        {/* <CustomerEmailField disabled={row.row.original.is_complete} item={row.row.original} />  */}
        <div className="d-flex w-100 justify-content-between align-items-center">
          <Blurred_Span style={{fontSize: '16px'}} blur={blur && (showEmailModal.item ? showEmailModal.item.signature_request_id === row.row.original.signature_request_id : false)}>{row.row.original.signatures && row.row.original.signatures[0].signer_email_address}</Blurred_Span>
          <ButtonGroup size="sm">
            <Button 
            disabled={row.row.original.is_complete}
            theme="white"
            onClick={(e) => {
              setShowEmailModal({item:row.row.original,show:true})
              setOriginalEmail(row.row.original.signatures[0].signer_email_address)
              setBlur(true)
            }}
            >
              <i className='material-icons'>{'edit'}</i>
            </Button>
          </ButtonGroup>
        
        </div>
      </div>)
  },
  {
    Header: props => <span style={{fontSize: '16px'}}>Agreement</span>,
    maxWidth: 350,
    accessor: 'template_name',
    Cell: row => ( <div className="text-nowrap h-100 d-flex justify-content-start align-items-center" style={{overflowX: 'auto', wordWrap: 'none'}}>
      <span style={{fontSize: '16px'}}>{row.row.original.metadata.template_title}</span>
    </div> )
  },
  {
    Header: props => <span style={{fontSize: '16px'}}>Sender</span>,
    width: 150,
    accessor: 'sender',
    Cell: row => ( <div className="h-100 d-flex justify-content-start align-items-center" style={{overflowX: 'auto', wordWrap: 'none'}}>
      <span style={{fontSize: '16px'}}>{`${row.row.original.metadata.sender || 'unknown'}`}</span>
    </div>)
  },
  {
    Header: props => <span style={{fontSize: '16px'}}>Date Sent</span>,
    maxWidth: 150,
    accessor: 'date',
    sortable: true,
    Cell: row => {
      let date = new Date(Number(row.row.original.created_at * 1000))
      return ( <div className="text-nowrap h-100 d-flex justify-content-start align-items-center" style={{overflowX: 'auto', wordWrap: 'none'}}>
          <span style={{fontSize: '16px'}}>{row.row.original.created_at && <Moment format="MMM Do YYYY h:mma" date={date} />}</span>
        </div> )
    }
  },
  {
    Header: props => <span style={{fontSize: '16px'}}>Status</span>,
    maxWidth: 150,
    accessor: 'status',
    Cell: row => ( <div className="text-nowrap h-100 d-flex justify-content-start align-items-center" style={{overflowX: 'auto', wordWrap: 'none'}}>
        <StatusInfo style={{fontSize: '16px'}} row={row.row} />
      </div>)
  },
  {
    Header: props => <span style={{fontSize: '16px'}}>Cancel</span>,
    maxWidth: 85,
    accessor: 'cancel',
    Cell: row => ( <span style={{fontSize: '16px'}} className="d-flex justify-content-center"><Button disabled={row.row.original.is_complete} onClick={(e) => {
      setShowModal({id:row.row.original.signature_request_id,show:true})
    }} >x</Button></span>)
  }]


  return (
    <div>
      <Card style={{width: '95vw'}} className="d-flex">
        <CardHeader className="d-flex justify-content-center align-items-center pb-2">
          <div className="ml-3" style={{width: '1.5rem', height: '1.5rem'}} ></div> 
          <h4 className="m-0">View and Modify Sent Requests</h4>
          
          {/* {(loading || !signatureList) ?
            <ReactLoading className="d-flex align-items-center p-0 pl-3" type={'spin'} color={'#0133b6'} width={'2.3rem'} height={'2.3rem'} />
          :
            <div className="d-flex align-items-center p-0 pl-3" style={{width: '2.3rem', height: '2.3rem'}} ></div>} */}

          {(loading || !signatureList) ?
          //  <ReactLoading className="d-flex align-items-center p-0 pl-3" type={'spin'} color={'#0133b6'} width={'2.3rem'} height={'2.3rem'} />
          <Spinner animation="border" role="status" style={{color:"#0133b6", width: '1.5rem', height: '1.5rem'}} className="ml-3">
            <span className="sr-only">Loading...</span>
          </Spinner>
          :
            <div className="ml-3" style={{width: '1.5rem', height: '1.5rem'}} ></div>  
          }
        </CardHeader>
        <CardBody>
          {/* {signatureList && <ReactTable 
            style={{
              border: '.15rem solid #e1e5eb',
              'borderRadius': '.25rem' 
            }}
            data={signatureList.signature_requests}
            columns={columns}
            minRows={0}
            sortable={false}
            showPagination={signatureList.signature_requests && signatureList.signature_requests.length > 10}
            // filterable={true}
          /> } */}
          {signatureList && 
            <HistoryTable 
              data={signatureList.signature_requests}
              columns={columns}
              num_pages={signatureList.list_info.num_pages}
              page_idx={signatureList.list_info.page}
            />}
            {/* <Row className="d-flex justify-content-center">
              <ReactLoading type={'spin'} color={'blue'} width={'10%'} height={'10%'} />
            </Row>} */}


            {/* CANCEL REQUEST MODAL */}
            <Modal 
            show={showModal.show}
            onHide={(e) => setShowModal({id:0,show:false})}
            >
              <Modal.Header closeButton><h4>Cancel Request</h4></Modal.Header>
              <Modal.Body><h5>
                Are you sure you want to cancel this request?</h5></Modal.Body>
              <Modal.Footer>
                <Button 
                theme="primary"
                style={{minWidth: '5vw'}}
                onClick={(e) => {
                  cancelSig(showModal.id)
                  setShowModal({id:0,show:false})
                }}
                >Yes</Button>
                <Button 
                theme="primary"
                style={{minWidth: '5vw'}}
                onClick={(e) => {
                  setShowModal({id:0,show:false})
                }}
                >No</Button>
              </Modal.Footer>
            </Modal>

            {/* UPDATE EMAIL MODAL */}
            {/* MOVE INPUT AND BUTTON CODE INTO CELL, MIRROR HOW CANCEL REQUEST FUNCTIONS. CAUSE IS MOST LIKELY FUNCTIONAL COMPONENT DEREFERENCING THE MODAL BEFORE IT CAN BE REMOVED COMPLETELY */}
            <Modal 
            onHide={() => setShowEmailModal({show:false})}
            show={showEmailModal.show}
            >
              <Modal.Header closeButton><h4 >Update Signee Email</h4></Modal.Header>
              <Modal.Body>
                <Form>
                  <label className="form-input-label">Confirm New Email Address</label>
                  <FormInput 
                    style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
                    invalid={!validEmail}
                    autoFocus
                    defaultValue={(showEmailModal.item ? showEmailModal.item.signatures ? showEmailModal.item.signatures[0].signer_email_address : emailValue : emailValue )}
                    onChange={(e) => {
                      let val = e.target.value
                      setEmailValue(val)
                      setValidEmail(testEmail(val) && (val !== originalEmail))
                    }}
                  />
                  {!validEmail && <FormFeedback>Please enter a new, valid email address.</FormFeedback>}
                </Form>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center">
                <Button 
                disabled={!validEmail}
                theme="primary"
                style={{minWidth: '5vw'}}
                onClick={updateRqsEmail}
                >Resend Signature Request</Button>
              </Modal.Footer>
            </Modal>

        </CardBody>
      </Card>
    </div>
  )
})



const CustomerEmailField = connect(mapStateToProps,mapDispatchToProps)((props) => {

  const [showInput,setShowInput] = useState(false)
  const [showModal,setShowModal] = useState(false)
  const [blur,setBlur] = useState(false)
  const [val,setVal] = useState(null)
  const [valid,setValid] = useState(true)
  const [original,setOriginal] = useState(null)
  // const inputRef = useRef(null)



  useEffect(() => {
    if(original === null)
      setOriginal(props.item.signatures && props.item.signatures[0].signer_email_address)
      setValid(false)
    if(val !== null) {
      setValid(testEmail(val) && val != original)
    }
  },[blur])

  const testEmail = (email) => {
    return EmailValidator.validate(email)
  }

  const handleClose = () => {
    setShowModal(false)
    setBlur(false)
    setVal(null)
    setValid(false)
  }

  const update = () => {
    if(val) {
      setValid(testEmail(val))
      if(valid) {
        props.updateEmail({
          Id: props.item.metadata.account_id,
          customerEmail: val || props.item.signatures[0].signer_email_address,
          sig_req_id: props.item.signature_request_id,
          sig_id: props.item.signatures[0].signature_id
        })
        setShowModal(false)
      }
    }
  }

return (
  <div className="d-flex w-100 justify-content-between align-items-center">
    {/* {showInput && <FormInput
      autoFocus
      defaultValue={props.item.signatures[0].signer_email_address}
      onChange={(e) => setVal(e.target.value)}
    />} */}
    <Blurred_Span style={{fontSize: '16px'}} blur={blur}>{props.item.signatures && props.item.signatures[0].signer_email_address}</Blurred_Span>
    <ButtonGroup size="sm">
      <Button 
      disabled={props.disabled}
      theme="white"
      onClick={(e) => {
        if(showInput && val) {
          props.updateEmail({
            Id: props.item.metadata.account_id,
            customerEmail: val || props.item.signatures[0].signer_email_address,
            sig_req_id: props.item.signature_request_id,
            sig_id: props.item.signatures[0].signature_id
          })
        }else {
          // setTimeout(() => {inputRef.current.focus()},1000)
        }
        setShowModal(true)
        setBlur(true)
      }}
      >
        <i className='material-icons'>{showInput ? 'done' : 'edit'}</i>
      </Button>
    </ButtonGroup>

    <UpdateModal  close={handleClose} show={showModal} 
                  valid={valid} 
                  value={val || (props.item.signatures && props.item.signatures[0].signer_email_address)} 
                  setVal={setVal}
                  update={update}  />

    {/* <Modal 
    onHide={handleClose}
    show={showModal}
    >
      <Modal.Header closeButton><h4 >Update Signee Email</h4></Modal.Header>
      <Modal.Body>
        <Form>
          <label className="form-input-label">Confirm New Email Address</label>
          <FormInput 
            style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
            invalid={!valid}
            autoFocus
            defaultValue={props.item.signatures && props.item.signatures[0].signer_email_address}
            onChange={(e) => setVal(e.target.value)}
          />
          {!valid && <FormFeedback>Please enter a new, valid email address.</FormFeedback>}
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button 
        disabled={!valid}
        theme="primary"
        style={{minWidth: '5vw'}}
        onClick={(e) => {
          if(val) {
            setValid(testEmail(val))
            if(valid) {
              props.updateEmail({
                Id: props.item.metadata.account_id,
                customerEmail: val || props.item.signatures[0].signer_email_address,
                sig_req_id: props.item.signature_request_id,
                sig_id: props.item.signatures[0].signature_id
              })
              setShowModal(false)
            }
          }
        }}
        >Resend Signature Request</Button>
      </Modal.Footer>
    </Modal> */}

  </div>
)})

const UpdateModal = ({close,show,valid,value,setVal,update}) => (
  <Modal 
    onHide={close}
    show={show}
    >
      <Modal.Header closeButton><h4 >Update Signee Email</h4></Modal.Header>
      <Modal.Body>
        <Form>
          <label className="form-input-label">Confirm New Email Address</label>
          <FormInput 
            style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
            invalid={!valid}
            autoFocus
            defaultValue={value}
            onChange={(e) => setVal(e.target.value)}
          />
          {!valid && <FormFeedback>Please enter a new, valid email address.</FormFeedback>}
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button 
        disabled={!valid}
        theme="primary"
        style={{minWidth: '5vw'}}
        onClick={update}
        >Resend Signature Request</Button>
      </Modal.Footer>
    </Modal>
)

const StatusInfo = ({row}) => {
  const template_title = row.original.metadata.template_title.replace(/[^a-zA-Z0-9-_]/g, '_').replace("__","_").replace("__","_").replace("__","_");
  const company_name = row.original.metadata.company_name.replace(/[^a-zA-Z0-9-_]/g, '_').replace("__","_").replace("__","_").replace("__","_");

  return (
    <div className="d-flex w-100 justify-content-between align-items-center">
      <span style={{fontSize: '16px'}}>{row.original.signatures && formatStatus(row.original.signatures[0].status_code)}</span>
      {(row.original.signatures && row.original.signatures[0].status_code === 'signed') && 
      <Download_A 
      download={`${row.original.title} ${row.original.metadata.company_name}`}
      href={`${url}/hellosign/signature-request/download/${row.original.signature_request_id}/${template_title} ${company_name}`} 
      className="material-icons">save_alt</Download_A>}
    </div>
  )
}

const formatStatus = (stat) => {
  if(stat) {
    switch(stat) {
      case 'success':
        return 'Success'
      case 'on_hold':
        return 'On Hold'
      case 'signed':
        return 'Signed'
      case 'awaiting_signature':
        return 'Awaiting Signature'
      case 'declined':
        return 'Declined'
      case 'error_unknown':
        return 'Unknown Error'
      case 'error_file':
        return 'Template Configuration Error 1-a'
      case 'error_component_position':
        return 'Template Configuration Error 1-b'
      case 'err_text_tag':
        return 'Template Configuration Error 1-c'
      case 'on_hold_by_requester':
        return 'On Hold By Requester'
      default:
        return 'No Status Found'
    }
  }
}

const Blurred_Span = styled.span`
  color: ${props => props.blur && 'transparent'};
  text-shadow: ${props => props.blur && '0 0 5px rgba(0,0,0,0.5)'};
`

const Download_A = styled.a`
  color: rgba(242, 107, 14, 1);
  &:hover {
    cursor: pointer;
    color: #0133b6;
  }
`
