import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, EmptyLayout, IconSidebar } from "./layouts";

// Route Views
import Home from './views/EsignHome'
import Analytics from "./views/Analytics";
import Login from "./views/Login";
import ResetPasswordRequest from './views/ResetPasswordRequest'
import UpdatePassword from './views/UpdatePassword'
import ForgotPassword from "./views/ForgotPassword";
import ChangePassword from "./views/ChangePassword";
import TransactionHistory from "./views/TransactionHistory";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";

const BlankIconSidebarLayout = ({ children }) => (
  <IconSidebar noNavbar noFooter>
    {children}
  </IconSidebar>
);

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/home" />
  },
  {
    path: "/home",
    layout: DefaultLayout,
    component: Home
  },
  {
    path: "/reset-password-request",
    layout: EmptyLayout,
    component: ResetPasswordRequest
  },
  {
    path: "/update-password",
    layout: EmptyLayout,
    component: UpdatePassword
  },

  // {
  //   path: "/analytics",
  //   layout: DefaultLayout,
  //   component: Analytics
  // },
  // {
  //   path: "/ecommerce",
  //   layout: DefaultLayout,
  //   component: OnlineStore
  // },
  // {
  //   path: "/blog-overview",
  //   layout: DefaultLayout,
  //   component: BlogOverview
  // },
  // {
  //   path: "/user-profile",
  //   layout: DefaultLayout,
  //   component: UserProfile
  // },
  // {
  //   path: "/user-profile-lite",
  //   layout: DefaultLayout,
  //   component: UserProfileLite
  // },
  // {
  //   path: "/edit-user-profile",
  //   layout: DefaultLayout,
  //   component: EditUserProfile
  // },
  {
    path: "/login",
    layout: EmptyLayout,
    component: Login
  },
  // {
  //   path: "/register",
  //   layout: BlankIconSidebarLayout,
  //   component: Register
  // },
  {
    path: "/forgot-password",
    layout: EmptyLayout,
    component: ForgotPassword
  },
  {
    path: "/change-password",
    layout: EmptyLayout,
    component: ChangePassword
  },
  // {
  //   path: "/file-manager-list",
  //   layout: DefaultLayout,
  //   component: FileManagerList
  // },
  // {
  //   path: "/file-manager-cards",
  //   layout: DefaultLayout,
  //   component: FileManagerCards
  // },
  {
    path: "/transaction-history",
    layout: DefaultLayout,
    component: TransactionHistory
  },
  // {
  //   path: "/calendar",
  //   layout: DefaultLayout,
  //   component: Calendar
  // },
  // {
  //   path: "/add-new-post",
  //   layout: DefaultLayout,
  //   component: AddNewPost
  // },
  {
    path: "/errors",
    layout: EmptyLayout,
    component: Errors
  },
  // {
  //   path: "/components-overview",
  //   layout: DefaultLayout,
  //   component: ComponentsOverview
  // },
  // {
  //   path: "/tables",
  //   layout: DefaultLayout,
  //   component: Tables
  // },
  // {
  //   path: "/blog-posts",
  //   layout: DefaultLayout,
  //   component: BlogPosts
  // },
  // {
  //   path: "/header-navigation",
  //   layout: HeaderNavigation,
  //   component: HeaderNav
  // },
  // {
  //   path: "/icon-sidebar-nav",
  //   layout: IconSidebar,
  //   component: IconSidebarView
  // }
];
