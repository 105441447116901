import React, { useEffect,useState,useRef } from 'react'
import { connect } from 'react-redux'
import ClientCheckbox from './clientCheckbox'
import Modal from 'react-bootstrap/Modal'
import { Card,CardHeader,CardBody,Col,Button,Row,Container,InputGroup,FormInput,Form } from 'shards-react'
import styled from 'styled-components'
import { getClientList, setClientSearchTimer, clearError, setSelectedClients, setClientSearchResults, setClientSearcher, setClientCheckAll, SetClientSearchText } from '../../redux/actions/newRequestActions'

const Styled_Div = styled.div`
  height: 100%;
  overflow-x: scroll;

`

const _Styled_Div = styled.div`
  /* width: 75%; */
  padding: 0.4375rem 0.75rem;
  cursor: pointer;
`

const Styled_Input = styled.input`
  :hover {
    cursor: pointer;
  }
  /* margin-right: .8em; */
  vertical-align: middle;
`

const mapStateToProps = state => ({
  // searcher: state.newRequest.clientSearcher,
  clients: state.newRequest.clients,
  checkAll: state.newRequest.checkAll,
  searchResults: state.newRequest.clientSearchResults,
})

const mapDispatchToProps = dispatch => ({
  setCheckAll: (check) => dispatch(setClientCheckAll(check)),
  setSearchResults: (res,txt) => dispatch(setClientSearchResults(res,txt)),
  setTimer: (func) => dispatch(setClientSearchTimer(func)),
  setSearchText: (text) => dispatch(SetClientSearchText(text)),
})

export default connect(mapStateToProps,mapDispatchToProps)(({data,searchResults,checkAll,searcher,setCheckAll,setSearchResults,setTimer,setSearchText}) => {

  const [show,setShow] = useState(false)


return (
  <Card
    style={{
      width: '100%',
      height: '50vh',
      'boxShadow':'none',
      border: '.15rem solid #e1e5eb',
    }}
    className="p-0">
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageCount,
              pageOptions,
            },
            null,
            2
          )}
        </code>
      </pre> */}
      <CardHeader className="p-0">
        <Container fluid className="file-manager__filters border-bottom">
          <Row>
            <Col className="file-manager__filters__rows d-flex justify-content-start" md="3">
            
            <MyFilter 
              searcher={searcher}
              checkAll={checkAll}
            />
            </Col>
            {/* SET PAGE LENGTH, MAY NOT BE NEEDED. */}
            <Col className="file-manager__filters__rows d-flex justify-content-end" md="9">
              
            </Col>
          </Row>
          {/* <Row>
            <Col className="file-manager__filters__rows d-flex justify-content-start" md="3">
              <Form className="d-flex justify-content-start pl-0">
                <_Styled_Div
                style={{fontSize: '16px', fontWeight: '700', color: '#212529'}}
                className="d-flex align-items-center pt-2 m-0 p-0"
                onClick={(e)=>{
                  setShow(true)
                }}
                >
                  <Styled_Input 
                  type="checkbox"
                  checked={checkAll}
                  />
                  Select/Deselect All
                </_Styled_Div>
              </Form>
            </Col>
          </Row> */}
        </Container>
      </CardHeader>
      <CardBody className="p-0">
        <Styled_Div>
          <table className="table table-bordered mb-0 sticky-header">
            <thead className="h-100" > 
              <tr>
                <th style={{verticalAlign: 'middle'}}>
                  {/* <div className="d-flex justify-content-center align-items-center" style={{height: '100%'}}>
                    <Styled_Input 
                    type="checkbox"
                    checked={checkAll}
                    onClick={(e) => {
                      setShow(true)
                    }}
                    />
                  </div> */}
                </th>
                <th>
                  <span style={{fontSize: '16px', fontWeight: '700'}}>Customer Company</span>
                </th>
                <th>
                  <span style={{fontSize: '16px', fontWeight: '700'}}>Customer Name</span>
                </th>
                <th>
                  <span style={{fontSize: '16px', fontWeight: '700'}}>Customer Email</span>
                </th>
              </tr>
            </thead>
            <tbody style={{height: '100%'}} >
              {data && data.map((d,i) => (
                <tr key={i}>
                  {/* CLIENT SELECT CHECKBOX */}
                  <td>
                    <div className="d-flex justify-content-center align-items-center" style={{height: '100%'}}>
                      <ClientCheckbox disabled={checkAll} checkAll={checkAll} client={d} />
                    </div>
                  </td>
                  {/* COMPANY NAME */}
                  <td>
                    <div className="d-flex justify-content-start align-items-center" style={{height: '100%'}}>
                      <span style={{fontSize: '16px'}}>{d.item ? d.item.name : d.name}</span>
                    </div>
                  </td>
                  {/* CUSTOMER NAME */}
                  <td>
                    <div className="d-flex justify-content-start align-items-center" style={{height: '100%'}}>
                      <span style={{fontSize: '16px'}}>{d.item ? d.item.customerName : d.customerName}</span>
                    </div>
                  </td>
                  {/* CUSTOMER EMAIL */}
                  <td>
                    <div className="d-flex justify-content-start align-items-center" style={{height: '100%'}}>
                      <span style={{fontSize: '16px'}}>{d.item ? d.item.customerEmail : d.customerEmail}</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Styled_Div>
      </CardBody>
      <Modal 
      show={show}
      onHide={(e) => setShow(false)}
      >
        <Modal.Header closeButton><h4>{checkAll ? 'Deselect All' : 'Select All'}</h4></Modal.Header>
        <Modal.Body>
          <h5>
            {checkAll ? 'All Companies will be deselected. Are you sure? All previous selections will be lost.' : 'All Companies will be Selected. Are you sure? All previous selections will be lost.'}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button 
          style={{width: '7.5rem'}}
          theme="primary"
          onClick={(e) => {
            // checkAllUtil.setAllClients(!checkAllUtil.checkAll ? clients : null)
            setCheckAll(!checkAll)
            if(!checkAll) 
              setSearchResults([])
            setShow(false)
          }}
          >Yes</Button>
          <Button
          style={{width: '7.5rem'}}
          theme="primary"
          onClick={(e) => {
            setShow(false)
          }}
          >No</Button>
        </Modal.Footer>
      </Modal>
    </Card>
  )
})

const MyFilter = connect(mapStateToProps,mapDispatchToProps)(({setTimer,setSearchResults,searcher,checkAll,setSearchText,searchText,clients}) => {

  const [text,setText] = useState(searchText || '')
  // const [searcher,setSearcher] = useState()
  const textRef = useRef(text)
  textRef.current = text
  
  if(checkAll && text.length > 0) setText(undefined)

  return (
    <InputGroup 
    // style={{width: '95%'}}
    className="w-100">
      <FormInput 
        className="form-control"
        size="sm"
        placeholder="Type to Search..."
        disabled={checkAll}
        value={textRef.current || ''}
        style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
        // onKeyPress={(e) => {
        //   if(e.charCode === 13) e.preventDefault()
        // }}
        // onKeyPress={(e) => {
        //   if(e.charCode === 13) e.preventDefault()
        //   setText(e.target.value)
        // }}
        onChange={(e) => {
          setText(e.target.value)
          let timer = setTimeout(async () => {
            // setGlobalFilter(textRef.current || undefined)
            setSearchText(textRef.current)
            if(textRef.current !== "") {
              let res = await clients.filter((c) => {
                return (c.name.toLowerCase().match(textRef.current.toLowerCase()) || 
                (c.customerName !== null && c.customerName.toLowerCase().match(textRef.current.toLowerCase())) ||
                (c.customerEmail !== null && c.customerEmail.toLowerCase().match(textRef.current.toLowerCase())))
              })
              setSearchResults(res)
            }else {
              setSearchResults([])
            }
            
            
          },300)
          setTimer(timer)
        }}
      />
    </InputGroup>
  )
})