/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect,useState } from "react";
import { Link,Redirect } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import EmailValidator from 'email-validator'
import ToastError from '../components/common/toastError'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  FormInput,
  FormCheckbox,
  Button
} from "shards-react";
import { connect } from "react-redux";
import { updateLoginForm, login, clearError, resetPasswordRequest } from '../redux/actions/authActions'

import styled from 'styled-components'
import background from '../images/backgrounds/background-1.jpg'
import { propTypes } from "react-bootstrap/esm/Image";

const H6 = styled.h6`
  color: red;
  text-align: center;
`

const _Styled_Img = styled.img`
  max-width: 13rem !important;
  max-height: 13rem !important;
`


const mapStateToProps = state => ({
  AUTH: state.auth.AUTH,
  error: state.auth.error,
  loading: state.auth.loading
})

const mapDispatchToProps = dispatch => ({
  update: (AUTH) => dispatch(updateLoginForm(AUTH)),
  login: (email,pass) => dispatch(login(email,pass)),
  clearError: () => dispatch(clearError()),
  resetPass: (eml) => dispatch(resetPasswordRequest(eml))
})

export default connect(mapStateToProps,mapDispatchToProps)(({AUTH,login,update,error,loading,history,clearError,resetPass}) => {
  
  const [valid,setIsValid] = useState(true)
  const [email,setEmail] = useState("")
  const [showModal,setShow] = useState(false)
  const [message,setMessage] = useState(null)
  const [sent,setIsSent] = useState(false)
  const [showError,setShowError] = useState(false)

  const set = () => {
    clearError()
    setShowError(false)
  }

  useEffect(() => {
    if(error !== undefined && error !== null) {
      setMessage(error)
      setIsSent(false)
    }
    if(loading) {
      setIsSent(true)
    }
    if(sent && !loading && !error) {
      setShow(true)
      setIsSent(false)
    }
    if(error && !showError) {
      setShowError(true)
    }
  })

  return (
  (AUTH.authenticated ? <Redirect to="/" /> :
  <Container fluid 
  onKeyPress={(e) => {
    if(showModal)
      e.preventDefault()
    if(e.charCode === 13) 
      if(valid) {
        resetPass(email)
      }

    //   login(AUTH.email,AUTH.pass)
  }}
  className="main-content-container h-75 px-4" 
    style={{ backgroundImage: `url(${background})`}}
  >
    {showError && <ToastError error={error} set={set}/>}
    <Row noGutters className="h-100">
      <Col lg="3" md="5" className="auth-form mx-auto my-auto">
        <Card>
          <CardBody>
            {/* Logo */}
            <_Styled_Img
              className="auth-form__logo d-table mx-auto m-0 pb-4"
              src={require('../../src/images/avatars/GSS_Logo_Vertical_Green.png')}
              style={{width: '35% !important'}}
              alt="GSS E-Sign"
            />

            {/* Title */}
            <h4 className=" text-center mb-4">
              Reset Password
            </h4>

            {/* Form Fields */}
            <Form>
              <FormGroup>
                <label className="form-input-label" htmlFor="exampleInputEmail1">Email address</label>
                <FormInput
                  type="email"
                  id="exampleInputEmail1"
                  placeholder="Enter email"
                  autoComplete="email"
                  style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
                  value={email}
                  invalid={!valid}
                  onKeyPress={(e) => {
                    if(e.charCode === 13)
                      e.preventDefault()
                  }}
                  onChange={(e) => {
                    let _val = e.target.value
                    setIsValid(EmailValidator.validate(_val))
                    setEmail(_val)
                    setMessage(null)
                  }}
                />
              </FormGroup>
              <Button
                // pill
                theme="primary"
                className="d-table mx-auto"
                onClick={(e) => {
                  if(valid) {
                    resetPass(email)
                  }
                }}
              >
                Reset Password
              </Button>
              <div className="auth-form__meta d-flex mt-3 justify-content-center">
                <Link to="/login">Back to Login</Link>
                {/* <Link to="/register" className="ml-auto">
                  Create a new account?
                </Link> */}
              </div> 
            </Form>
          </CardBody>
        </Card>

      </Col>
      <Modal 
    // onHide={(e) => {
    //   setShowModal(false)
    //   setBlur(false)
    // }}
    show={showModal}
    onHide={() => {
      //do nothing, if this isn't defined it throws an error.
    }}
    >
      <Modal.Header closeButton={false}><h4 >Reset Confirmation</h4></Modal.Header>
      <Modal.Body>
        <h5>An email has been sent with further instructions <br/> on how to change your password.</h5>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button 
        theme="primary"
        style={{minWidth: '5vw'}}
        onClick={(e) => {
          setShow(false)
          history.push('/login')
        }}
        >Back to Login</Button>
      </Modal.Footer>
    </Modal>
    </Row>
  </Container>)
)})
