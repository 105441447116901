import React, { useEffect,useState,useRef } from 'react'
import { FormSelect,FormInput,Card,CardHeader,CardBody,CardFooter,Button,Container,Row,Col,InputGroup,InputGroupAddon } from 'shards-react'
import { useTable,usePagination } from 'react-table'
import { connect } from 'react-redux'
import { setHistorySearchTimer } from '../../redux/actions/layoutActions'
import { updateSignatureListPrams,setSearchText } from '../../redux/actions/historyActions'


const mapStateToProps = state => ({
  params: state.history.params,
  templateFilters: state.newRequest.templates,
  searchText: state.history.searchText
})

const mapDispatchToProps = dispatch => ({
  updateParams: (params) => dispatch(updateSignatureListPrams(params)),
  setSearchText: (text) => dispatch(setSearchText(text)),
  setTimer: (func) => dispatch(setHistorySearchTimer(func))
})

export default connect(mapStateToProps,mapDispatchToProps)
(({columns,data,page_idx,page_size,num_pages,params,updateParams,templateFilters,searchText,setSearchText,setTimer}) => {

  const [searchVal,setSearchVal] = useState()
  const [filterVal,setFilterVal] = useState('All Agreements')

  const searchRef = useRef(searchVal)
  const paramsRef = useRef(params)

  searchRef.current = searchVal
  paramsRef.current = params

  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,


      //pagination consts
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize }
    } = useTable(
      {
        columns,
        data,
        initialState: {pageIndex: page_idx,pageSize: page_size},
        manualPagination: true,
      },
      usePagination
    )

  return (
    <Card 
    style={{
      'boxShadow':'none',
      border: '.15rem solid #e1e5eb',
    }}
    className="p-0">
      <CardHeader className="p-0">
        <Container fluid className="file-manager__filters border-bottom">
          <Row>
            <Col className="file-manager__filters__rows d-flex justify-content-start" md="10">
            <InputGroup 
            style={{width: '25%'}}
            className="">
              <FormInput 
                className="form-control"
                size="sm"
                placeholder="Type to Search..."
                value={searchRef.current}
                style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
                onChange={(e) => {
                  let _val = e.target.value
                  setSearchVal(_val)
                  
                  let time = setTimeout(() => {
                    if(!searchRef.current || searchRef.current.length === 0) {
                      delete paramsRef.current.query
                      updateParams({
                        ...paramsRef.current 
                      })
                    }else {
                      setFilterVal('All Agreements')
                      updateParams({
                        ...paramsRef.current,
                        query: searchRef.current 
                      })
                    }
                  },1500)
                  setTimer(time)
                }}
                // onKeyPress={(e) => {
                //   if(e.charCode === 13 && searchText) {
                //     updateParams({
                //       ...params,
                //       query: searchText
                //     })
                //   }
                //   else if(e.charCode === 13 && !searchText) {
                //     let _p = params
                //     delete _p.query
                //     updateParams({
                //       ..._p
                //     })
                //   }
                // }}
              />
              {/* <InputGroupAddon type="append">
                <Button 
                theme="white"
                onClick={(e) => {
                  if(searchText) {
                    updateParams({
                      ...params,
                      query: searchText
                    })
                  }else {
                    let _p = params
                    delete _p.query
                    updateParams({
                      ..._p
                    })
                  }
                }}
                >
                  <i className="material-icons">search</i>
                </Button>
              </InputGroupAddon> */}
            </InputGroup>
              
              {/* FILTER FOR AGREEMENT */}
              <InputGroup
              style={{width: '25%'}}
              className=""
              >
                <FormSelect 
                className="form-control"
                // 'minHeight':'3.5rem','marginTop':'0px','bordeRadius':'.275rem', 
                style={{'minHeight':'2.65rem','marginTop':'0px','bordeRadius':'.275rem', fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
                value={filterVal}
                onChange={(e) => {
                  let val = e.target.children[e.target.selectedIndex].value
                  setFilterVal(val)
                  if(val !== 'All Agreements') {
                    setSearchVal('')
                  }
                  let query = params.query || {}
                  updateParams({
                    ...params,
                    query: (val !== 'All Agreements' ? `metadata:${val}` : '')
                  })
                }}
                >
                  {/* map children in as options */}
                  <option>All Agreements</option>
                  {templateFilters && templateFilters.map((_t,i) => (
                    <option key={i}>{_t.title}</option>
                  ))}
                </FormSelect>
              </InputGroup>
            </Col>
            <Col className="file-manager__filters__rows d-flex justify-content-end" md="2">
              <div className="d-flex align-items-center">
              <span  style={{fontSize: '16px', fontWeight: '700', color: '#212529'}} className="m-0 mr-3">Show</span>
              </div>
              <FormSelect
              className="form-control"
              //'minHeight':'3.5rem','marginTop':'0px','bordeRadius':'.275rem',
              style={{'minHeight':'2.5rem','marginTop':'0px','bordeRadius':'.275rem', fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
                size="sm"
                value={params.page_size}
                onChange={(e) => {
                  updateParams({
                    ...params,
                    page: 0,
                    page_size: e.target.children[e.target.selectedIndex].value
                  })
                }}
              >
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
              </FormSelect>
            </Col>
          </Row>
        </Container>
      </CardHeader>
      <CardBody className="p-0">
        <div className="" style={{overflowX: 'auto'}}>
          <table className="table table-bordered h-100">
          <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="h-100" {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr className="h-100" {...row.getRowProps()} >
                    {row.cells.map(cell => {
                      return <td className="h-100" {...cell.getCellProps()}>{cell.render('Cell')}</td> //use cell.row.original to access obj
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </CardBody>
      {(num_pages > 1) &&
      <CardFooter className="p-0">
        <Container fluid className="file-manager__filters border-top">
          <Row>
            {/* previous button */}
          <Col className="file-manager__filters__rows d-flex" md="4">
              <Button 
              disabled={page_idx === 1} 
              theme="primary"
              style={{width: '10rem'}}
              onClick={(e) => {
                updateParams({
                  ...params,
                  page: page_idx-1
                })
              }}
              >Previous</Button>
          </Col>
          {/* select page */}
          <Col className="file-manager__filters__rows d-flex justify-content-center" md="4">
            <div className="d-flex align-items-center">
              <span style={{fontSize: '16px', fontWeight: '700', color: '#212529'}}>Page</span>
              <FormSelect 
                style={{'minHeight':'2.5rem','marginTop':'0px','bordeRadius':'.275rem', fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
                disabled={num_pages === 1}
                value={page_idx}
                onChange={(e) => {
                  updateParams({
                    ...params,
                    page: e.target.children[e.target.selectedIndex].value
                  })
                }}
              >
                {num_pages && buildPageOptions(num_pages).map((num, i) => (
                  <option key={i}>{num}</option>
                ))}
              </FormSelect>
              <span style={{fontSize: '16px', fontWeight: '700', color: '#212529'}}>of {num_pages}</span>
            </div>
              
          </Col>
          {/* next button */}
          <Col className="file-manager__filters__rows d-flex justify-content-end" md="4">
            <Button 
            theme="primary"
            style={{width: '10rem'}}
            disabled={page_idx === num_pages}
            onClick={(e) => {
              updateParams({
                ...params,
                page: page_idx+1
              })
            }}
            >Next</Button>
          </Col>
          </Row>
        </Container>
      </CardFooter>}
    </Card>
  )
})

const buildPageOptions = (length) => {
  let i = 1
  let arr = []
  while(i <= length) {
    arr.push(i)
    i++
  }
  return arr
}

const formatStatus = (stat) => {
  if(stat) {
    switch(stat) {
      case 'success':
        return 'Success'
      case 'on_hold':
        return 'On Hold'
      case 'signed':
        return 'Signed'
      case 'awaiting_signature':
        return 'Awaiting Signature'
      case 'declined':
        return 'Declined'
      case 'error_unknown':
        return 'Unknown Error'
      case 'error_file':
        return 'Template Configuration Error 1-a'
      case 'error_component_position':
        return 'Template Configuration Error 1-b'
      case 'err_text_tag':
        return 'Template Configuration Error 1-c'
      case 'on_hold_by_requester':
        return 'On Hold By Requester'
      default:
        return 'No Status Found'
    }
  }
}