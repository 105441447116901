import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import ReactTable from 'react-table-6'
import EmailValidator from 'email-validator'
import ReactTooltip from 'react-tooltip'
import ConfirmDetailsReactTable from './confirmDetailsReactTable'

import { updateClient, updateRequest, updateClientLocal, updateClientLocalQuiet, setSelectedClients } from '../../redux/actions/newRequestActions'
import { setAuthzTimer,authz } from '../../redux/actions/authActions'
import { 
  Card,CardHeader,CardBody,FormInput,Col,Button,ButtonGroup,FormSelect,FormFeedback } from 'shards-react'
import { connect } from 'react-redux'
import { updateCustomerEmail } from '../../redux/actions/historyActions'


const Ul = styled.ul`
  padding: 0;
  list-style-type: none;
  text-align: center;
  font-size: 1.2em;
  max-height: 200px;
  width: 75%;
  overflow-y: auto;
  border: solid 2px #e1e5eb;
  border-radius: .25rem;
`

const mapStateToProps = state => ({
  _clients: state.newRequest.clients,
  selectedClients: state.newRequest.selectedClients,
  templateMapper: state.newRequest.templateMapper
})

const mapDispatchToProps = dispatch => ({
  //updates array, replacing array, causing re-render
  updateClient: (client) => dispatch(updateClientLocal(client)),
  //updates without causing re-render
  updateClientQuiet: (client) => dispatch(updateClientLocalQuiet(client)),
  setSelectedClients: (arr) => dispatch(setSelectedClients(arr)),
  updateRequest: (req) => dispatch(updateRequest(req)),
  setAuthTimer: () => dispatch(setAuthzTimer())
})

// const columns = [{
//   Header: 'Customer Company',
//   accessor: 'name',
//   Cell: row => (<span>{row.original.name}</span>)
// }, 
// {
//   Header: 'Customer Name',
//   accessor: 'cname',
//   Cell: row => ( <CustomerField customer={row.original} update={this.update} tag='customerName' /> )
// },
// {
//   Header: 'Customer Email',
//   accessor: 'email',
//   Cell: row => ( <CustomerField customer={row.original} update={this.update} tag='customerEmail' /> )
// }]

class ConfirmCustomerDetails extends React.Component {
  
  constructor(props) {
    super(props)

    this.state = {
      columns: [],
      pageIndex: undefined
    }

    this.update = this.update.bind(this)
    this.setPageIndex = this.setPageIndex(this)
  }

  update = (client) => {
    let _client = this.props.selectedClients.find(_client => _client.Id === client.Id)
    if(_client) {
      this.props.updateClient({
        ..._client,
        ...client
      })
    }
  }
  
  setPageIndex(i) {
    console.log('set page index: ', i)
    // this.setState({pageIndex:i})
  }

  componentDidMount() {

    this.props.setAuthTimer()

    //ADDING ALL 'CHECKED' CLIENTS TO THE SELECTEDCLIENTS ARRAY
    if(this.props._clients && this.props._clients.length > 0 ) {
      let arr = []
      for(let i in this.props._clients) {
        if(this.props._clients[i].checked) {
          let _c
          if(this.props.selectedClients && (_c = this.props.selectedClients.find(c => c.Id === this.props._clients[i].Id))) {
            arr.push(_c)
          }else arr.push(this.props._clients[i].item ? this.props._clients[i].item : this.props._clients[i])
          
        }
      }
      this.props.setSelectedClients(arr)
    }

    let cols = [{
      Header: props => <span style={{fontSize: '16px', fontWeight: '700'}}>Customer Company</span>,
      accessor: 'name',
      Cell: ({row}) => (<div className="d-flex justify-content-start align-items-center" style={{height: '100%'}}>
        <span style={{fontSize: '16px'}}>{row.original.name}</span>
      </div>)
    }, 
    {
      Header: props => <span style={{fontSize: '16px', fontWeight: '700'}}>Customer Name</span>,
      accessor: 'cname',
      Cell: ({row}) => ( <div className="d-flex justify-content-start align-items-center" style={{height: '100%'}}>
          <CustomerField customer={row.original} update={this.update} tag='customerName' /> 
        </div>)
    },
    {
      Header: props => <span style={{fontSize: '16px', fontWeight: '700'}}>Customer Email</span>,
      accessor: 'email',
      Cell: ({row}) => ( <div className="d-flex justify-content-start align-items-center" style={{height: '100%'}}>
        <CustomerField customer={row.original} update={this.update} tag='customerEmail' /> 
      </div>)
    },
    {
      Header: props => <span style={{fontSize: '16px', fontWeight: '700'}}>GSS Email</span>,
      accessor: 'cc',
      Cell: ({row}) => ( <div className="d-flex justify-content-start align-items-center" style={{height: '100%'}}>
        <CustomerField customer={row.original} update={this.update} tag='ccEmail' /> 
      </div>)
    }]

    //ADD PERCENT DROPDOWN IF FIELD EXISTS ON TEMPLATE
    if(this.props.templateMapper && this.props.templateMapper.with_percent_value) {
      cols.push({
        Header: props => <span style={{fontSize: '16px', fontWeight: '700'}}>Renewal Percent</span>,
        accessor: 'percent',
        Cell: ({row}) => (<div className="d-flex justify-content-start align-items-center" style={{height: '100%'}}>
            <PercentDropdown customer={row.original} />
          </div>)
      })
    }

    //ADD TERM DROPDOWN IF FIELD EXISTS ON TEMPLATE
    if(this.props.templateMapper && this.props.templateMapper.with_renewal_term) {
      cols.push({
        Header: props => <span style={{fontSize: '16px', fontWeight: '700'}}>Renewal Term</span>,
        accessor: 'term',
        Cell: ({row}) => (<div className="d-flex justify-content-start align-items-center" style={{height: '100%'}}>
            <TermDropdown customer={row.original} />
          </div>)
      })
    }

    this.setState({columns: cols})
  }

  componentDidUpdate(prevProps,prevState) {
    this.props.setAuthTimer()
  }
  
  
  render() {
    return (
      <div>
        <Card className="d-flex align-items-stretch" style={{width: '95vw'}}>
          <CardHeader className="d-flex justify-content-center align-items-center pb-2">
            
            <h4 className="m-0">Confirm Customer Details Signature Request</h4>
            
            {/* <div className="d-flex align-items-center p-0 pl-3" style={{width: '2.3rem', height: '2.3rem'}} ></div>   */}
            
          </CardHeader>
          <CardBody className="d-flex justify-content-center align-items-center" >
            <Col lg="12" style={{minHeight: '50vh'}} className="p-0">
              {/* <ReactTable 
                style={{
                  border: '.15rem solid #e1e5eb',
                  'borderRadius': '.25rem' 
                }}
                data={this.props.clients}
                columns={this.state.columns}
                minRows={0}
                showPagination={this.props.clients && this.props.clients.length > 5}
                showPageSizeOptions={true}
                sortable={false}
                resizeable={false}
                filterable={false}
                pageSizeOptions={[5,10,15,20]}
                defaultPageSize={5}
              /> */}
              {this.props.selectedClients && 
              <ConfirmDetailsReactTable 
                index={this.state.pageIndex}
                setPageIndex={(i) => {
                  this.setState({pageIndex: i})
                }}
                data={this.props.selectedClients}
                columns={this.state.columns}
              />}
            </Col>
            
          </CardBody>
        </Card>
        {/* CURRENT REACT-TABLE BUILD, NEEDS WORK */}
        {/* <MyTable
          data={data}
          columns={columns}
        /> */}
      </div>
    )
  }   
}


const CustomerField = connect(mapStateToProps,mapDispatchToProps)((props) => {

  const [showInput,setShowInput] = useState(false)
  const [client,updateClient] = useState(false)
  const [valid,setIsValid] = useState(true)
  let tipRef = useRef(null)

  const testEmail = (email) => {
    let valid = (email.length > 0 ? EmailValidator.validate(email) : true)
    setIsValid(valid)
    return valid
  }

  useEffect(() => {

    // if(valid) {
    //   props.updateClientQuiet(client)
    // }

    // if((props.tag === 'customerEmail' && props.customer.customerEmail) && !client) {
    //   updateClient(props.customer)
    //   testEmail(props.customer.customerEmail)
    // }else {
    //   testEmail(client.customerEmail)
    // }

    if (props.tag === 'customerEmail' && !props.customer.hasOwnProperty('validEmail')) {
      props.update({ ...props.customer, validEmail: (testEmail(props.customer.customerEmail) || false) });
    }

    if (props.tag === 'ccEmail' && !props.customer.hasOwnProperty('validCC')) {
      props.update({ ...props.customer, validCC: true });
    }

    if((props.tag === 'ccEmail' && !valid) || (props.tag === 'customerEmail' && !valid)) {
      ReactTooltip.show(tipRef)
    }else {
      ReactTooltip.hide(tipRef)
    }
  })

  let classes = (props.tag === 'ccEmail' && !valid) || (props.tag === 'customerEmail' && !valid) ? "is-invalid" : ""

  return (
    <div className="d-flex w-100 justify-content-between align-items-center">
      <FormInput
        style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
        invalid={!valid && (props.tag === 'customerEmail' || props.tag === 'ccEmail')}
        // className={"form-control-lg " + classes}
        value={props.customer[props.tag]}
        // onBlur={(e) => props.updateClient(props.customer)}
        onChange={(e) => {
          let _val = e.target.value
          if(props.tag === 'customerEmail' || props.tag === 'ccEmail') {
            // console.log('calling update quiet for customerEmail')
            props.update({
              ...props.customer,
              validEmail: (props.tag === 'customerEmail' ? testEmail(_val) : (props.customer.validEmail || false)),
              validCC: (props.tag === 'ccEmail' ? testEmail(_val) : (props.customer.validCC || true)),
              [props.tag]:_val
            })
          }else {
            // console.log('calling update quiet for customerName')
            props.update({
              ...props.customer,
              [props.tag]:_val
            })
          }
        }}
      />
      <p
        ref={ref => tipRef = ref}
        data-for={`${props.customer.Id}-${props.tag}`}
        data-tip="Please enter a valid email"
      ></p>
      <ReactTooltip  
      // disable={valid}
      place="top"
      id={`${props.customer.Id}-${props.tag}`} />
      {/* {(!props.customer.validEmail && props.tag === 'customerEmail') && 
      <div className="d-flex w-100">
        <span style={{color: 'red', fontSize: '10px'}}>Please enter a valid Email.</span>
      </div>} */}
    </div>
)})


const TermDropdown = connect(mapStateToProps,mapDispatchToProps)(({updateClient,customer,templateMapper}) => {
  return (
    <FormSelect
      className="percent-dropdown"
      style={{'minHeight':'2.65rem','marginTop':'0px','bordeRadius':'.275rem'}}
      onChange={(e) => {
        updateClient({
          ...customer,
          renewalTerm: e.target.children[e.target.selectedIndex].value
        })
      }}
      defaultValue={customer.renewalTerm || templateMapper.renewalTerm}
    >
      <option>1 Year</option>
      <option>2 Years</option>
      <option>3 Years</option>
      <option>4 Years</option>
      <option>5 Years</option>
    </FormSelect>
  )
})

const PercentDropdown = connect(mapStateToProps,mapDispatchToProps)(({updateClient,customer,templateMapper}) => {

  return (
    <FormSelect
      className="percent-dropdown"
      style={{'minHeight':'2.65rem','marginTop':'0px','bordeRadius':'.275rem'}}
      onChange={(e) => {
        updateClient({
          ...customer,
          renewalFee: e.target.children[e.target.selectedIndex].value
        })
      }}
      defaultValue={customer.renewalFee || templateMapper.renewalFee}
    >
      <option>Zero Percent (0%)</option>
      <option>One Percent (1%)</option>
      <option>Two Percent (2%)</option>
      <option>Three Percent (3%)</option>
      <option>Four Percent (4%)</option>
      <option>Five Percent (5%)</option>
      <option>Six Percent (6%)</option>
      <option>Seven Percent (7%)</option>
      <option>Eight Percent (8%)</option>
      <option>Nine Percent (9%)</option>
      <option>Ten Percent (10%)</option>
      <option>Eleven Percent (11%)</option>
      <option>Twelve Percent (12%)</option>
      <option>Thirteen Percent (13%)</option>
      <option>Fourteen Percent (14%)</option>
      <option>Fifteen Percent (15%)</option>
      <option>Sixteen Percent (16%)</option>
      <option>Seventeen Percent (17%)</option>
      <option>Eighteen Percent (18%)</option>
      <option>Nineteen Percent (19%)</option>
      <option>Twenty Percent (20%)</option>
      <option>Twenty-One Percent (21%)</option>
      <option>TTwenty-wo Percent (22%)</option>
      <option>Twenty-Three Percent (23%)</option>
      <option>Twenty-Four Percent (24%)</option>
      <option>Twenty-Five Percent (25%)</option>
      <option>Twenty-Six Percent (26%)</option>
      <option>Twenty-Seven Percent (27%)</option>
      <option>Twenty-Eight Percent (28%)</option>
      <option>Twenty-Nine Percent (29%)</option>
      <option>Thirty Percent (30%)</option>
      <option>Thirty-One Percent (31%)</option>
      <option>Thirty-Two Percent (32%)</option>
      <option>Thirty-Three Percent (33%)</option>
      <option>Thirty-Four Percent (34%)</option>
      <option>Thirty-Five Percent (35%)</option>
      <option>Thirty-Six Percent (36%)</option>
      <option>Thirty-Seven Percent (37%)</option>
      <option>Thirty-Eight Percent (38%)</option>
      <option>Thirty-Nine Percent (39%)</option>
      <option>Fourty Percent (40%)</option>
      <option>Fourty-One Percent (41%)</option>
      <option>Fourty-Two Percent (42%)</option>
      <option>Fourty-Three Percent (43%)</option>
      <option>Fourty-Four Percent (44%)</option>
      <option>Fourty-Five Percent (45%)</option>
      <option>Fourty-Six Percent (46%)</option>
      <option>Fourty-Seven Percent (47%)</option>
      <option>Fourty-Eight Percent (48%)</option>
      <option>Fourty-Nine Percent (49%)</option>
      <option>Fifty Percent (50%)</option>
      <option>Fifty-One Percent (51%)</option>
      <option>Fifty-Two Percent (52%)</option>
      <option>Fifty-Three Percent (53%)</option>
      <option>Fifty-Four Percent (54%)</option>
      <option>Fifty-Five Percent (55%)</option>
      <option>Fifty-Six Percent (56%)</option>
      <option>Fifty-Seven Percent (57%)</option>
      <option>Fifty-Eight Percent (58%)</option>
      <option>Fifty-Nine Percent (59%)</option>
      <option>Sixty Percent (60%)</option>
    </FormSelect>
  )
})


export default connect(mapStateToProps,mapDispatchToProps)(ConfirmCustomerDetails)