import React, { useState,useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useTable, usePagination, useGlobalFilter } from 'react-table'
import Modal from 'react-bootstrap/Modal'
import { setClientSearchTimer, setClientSearchResults, SetClientSearchText } from '../../redux/actions/newRequestActions'
import {connect} from 'react-redux'
import Fuse from 'fuse.js'

import { Card,CardHeader,CardBody,Col,Button,Row,Container,InputGroup,FormInput,Form } from 'shards-react'

const Styled_Div = styled.div`
  height: 100%;
  overflow-x: scroll;

`

const _Styled_Div = styled.div`
  /* width: 75%; */
  padding: 0.4375rem 0.75rem;
  cursor: pointer;
`

const Styled_Input = styled.input`
  :hover {
    cursor: pointer;
  }
  margin-right: .8em;
  vertical-align: middle;
`

const mapStateToProps = state => ({
  searchText: state.newRequest.clientSearchText
})

const mapDispatchToProps = dispatch => ({
  setTimer: (func) => dispatch(setClientSearchTimer(func)),
  setSearchResults: (res,txt) => dispatch(setClientSearchResults(res,txt)),
  setSearchText: (text) => dispatch(SetClientSearchText(text)),
})

export default connect(null,mapDispatchToProps)(({columns,data,searchUtil,checkAllUtil,clients,setTimer,setSearchResults}) => {

  const [num_pages,setNumPages] = useState(0)
  const [show,setShow] = useState(false)
  // const [text,setText] = useState('')
  // const [searcher,setSearcher] = useState()

  // const textRef = useRef(text)
  // textRef.current = text
  // let _data
  // if(data && data.length > 0) _data = data.sort((a,b) => (a.checked === b.checked ? 0 : (a.checked ? 1 : -1)))

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter
  } = useTable({
    columns,
    data
  },
  useGlobalFilter 
)

  // useEffect(() => {
  //   setNumPages(Math.ceil(data.length / pageSize))
  // },[pageSize,pageIndex,data])

  
  
  

  return (
    
    <Card
    style={{
      width: '100%',
      height: '50vh',
      'boxShadow':'none',
      border: '.15rem solid #e1e5eb',
    }}
    className="p-0">
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageCount,
              pageOptions,
            },
            null,
            2
          )}
        </code>
      </pre> */}
      <CardHeader className="p-0">
        <Container fluid className="file-manager__filters border-bottom">
          <Row>
            <Col className="file-manager__filters__rows d-flex justify-content-start" md="3">
            {/* <InputGroup 
            style={{width: '95%'}}
            className="">
              <FormInput 
                className="form-control"
                size="sm"
                placeholder="Type to Search..."
                value={searchUtil.val}
                style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
                onKeyPress={(e) => {
                  if(e.charCode === 13) e.preventDefault()
                }}
                onChange={(e) => {
                  const _val = e.target.value
                  searchUtil.setVal(_val)
                  searchUtil.setSearchResults(searchUtil.searcher.search(_val))
                }}
              />
            </InputGroup> */}
            <MyFilter 
              searcher={searchUtil.searcher}
              checkAll={checkAllUtil.checkAll}
            />
            </Col>
            {/* SET PAGE LENGTH, MAY NOT BE NEEDED. */}
            <Col className="file-manager__filters__rows d-flex justify-content-end" md="9">
              {/* <div className="d-flex align-items-center">
              <span  style={{fontSize: '16px', fontWeight: '700', color: '#212529'}} className="m-0 mr-3">Show</span>
              </div>
              <FormSelect
              className="form-control"
              //'minHeight':'3.5rem','marginTop':'0px','bordeRadius':'.275rem',
              style={{'minHeight':'2.5rem','marginTop':'0px','bordeRadius':'.275rem', fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
                size="sm"
                onChange={(e) => {
                  setPageSize(Number(e.target.children[e.target.selectedIndex].value))
                  gotoPage(0)
                }}
              >
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
              </FormSelect> */}
            </Col>
          </Row>
          <Row>
            <Col className="file-manager__filters__rows d-flex justify-content-start" md="3">
              <Form className="d-flex justify-content-start pl-0">
                <_Styled_Div
                style={{fontSize: '16px', fontWeight: '700', color: '#212529'}}
                className="d-flex align-items-center pt-2 m-0 p-0"
                onClick={()=>{
                  // if(!checkAllUtil.checkAll) {
                  //   //mark all as selected, add all
                  //   checkAllUtil.setAllClients(clients)
                  //   checkAllUtil.setCheckAll(!checkAllUtil.checkAll)
                  // }else {
                  //   checkAllUtil.setAllClients(null)
                  //   checkAllUtil.setCheckAll(!checkAllUtil.checkAll)
                  // }
                  setShow(true)
                }}
                >
                  <Styled_Input 
                  type="checkbox"
                  checked={checkAllUtil.checkAll}
                  />
                  Select/Deselect All
                </_Styled_Div>
              </Form>
            </Col>
          </Row>
        </Container>
      </CardHeader>
      <CardBody className="p-0">
        <Styled_Div>
          <table className="table table-bordered mb-0 sticky-header" {...getTableProps()} >
            <thead className="" > 
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th style={{textAlign: 'left'}} {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody style={{height: '100%'}} {...getTableBodyProps()} >
              {rows.map(
                (row, i) => {
                  prepareRow(row)
                  return (
                    <tr style={{height: '72px'}} {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return <td className="h-100" style={{verticalAlign: 'middle'}} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )}
              )}
            </tbody>
          </table>
        </Styled_Div>
      </CardBody>
      <Modal 
      show={show}
      onHide={(e) => setShow(false)}
      >
        <Modal.Header closeButton><h4>{checkAllUtil.checkAll ? 'Deselect All' : 'Select All'}</h4></Modal.Header>
        <Modal.Body>
          <h5>
            {checkAllUtil.checkAll ? 'All Companies will be deselected. Are you sure? All previous selections will be lost.' : 'All Companies will be Selected. Are you sure? All previous selections will be lost.'}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button 
          style={{width: '7.5rem'}}
          theme="primary"
          onClick={(e) => {
            // checkAllUtil.setAllClients(!checkAllUtil.checkAll ? clients : null)
            checkAllUtil.setCheckAll(!checkAllUtil.checkAll)
            if(!checkAllUtil.checkAll) 
              setSearchResults(undefined)
            setShow(false)
          }}
          >Yes</Button>
          <Button
          style={{width: '7.5rem'}}
          theme="primary"
          onClick={(e) => {
            setShow(false)
          }}
          >No</Button>
        </Modal.Footer>
      </Modal>
    </Card>
  )
})


const MyFilter = connect(mapStateToProps,mapDispatchToProps)(({setTimer,setSearchResults,searcher,checkAll,setSearchText,searchText}) => {

  const [text,setText] = useState(searchText || '')
  // const [searcher,setSearcher] = useState()
  const textRef = useRef(text)
  textRef.current = text
  
  if(checkAll && text.length > 0) setText('')

  return (
    <InputGroup 
    // style={{width: '95%'}}
    className="w-100">
      <FormInput 
        className="form-control"
        size="sm"
        placeholder="Type to Search..."
        disabled={checkAll}
        value={textRef.current || ''}
        style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
        // onKeyPress={(e) => {
        //   if(e.charCode === 13) e.preventDefault()
        // }}
        // onKeyPress={(e) => {
        //   if(e.charCode === 13) e.preventDefault()
        //   setText(e.target.value)
        // }}
        onChange={(e) => {
          setText(e.target.value)
          let timer = setTimeout(() => {
            // setGlobalFilter(textRef.current || undefined)
            console.log('setting search results based on text:: ', textRef.current)
            setSearchResults(searcher.search(textRef.current),textRef.current)
            setSearchText(textRef.current)
          },700)
          setTimer(timer)
        }}
      />
    </InputGroup>
  )
})

// const buildPageOptions = (length) => {
//   let i = 1
//   let arr = []
//   while(i <= length) {
//     arr.push(i)
//     i++
//   }
//   return arr
// }


// PAGINATION CODE FOR CARDFOOTER

// {data.length > pageSize && <CardFooter className="p-0">
//         <Container fluid className="file-manager__filters border-top">
//           <Row>
//             {/* previous button */}
//           <Col className="file-manager__filters__rows d-flex" md="4">
//               <Button 
//               disabled={!canPreviousPage} 
//               theme="primary"
//               style={{width: '10rem'}}
//               onClick={(e) => {previousPage()}}
//               >Previous</Button>
//           </Col>
//           {/* select page */}
//           <Col className="file-manager__filters__rows d-flex justify-content-center" md="4">
//             <div className="d-flex align-items-center">
//               <span style={{fontSize: '16px', fontWeight: '700', color: '#212529'}}>Page</span>
//               <FormSelect 
//                 style={{'minHeight':'2.5rem','marginTop':'0px','bordeRadius':'.275rem', fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
//                 disabled={pageOptions.length === 0}
//                 value={pageIndex + 1}
//                 onChange={(e) => gotoPage(Number(e.target.children[e.target.selectedIndex].value - 1))}
//               >
//                 {pageOptions.length > 0 && pageOptions.map((num, i) => (
//                   <option key={i}>{i}</option>
//                 ))}
//               </FormSelect>
//               <span style={{fontSize: '16px', fontWeight: '700', color: '#212529'}}>of {pageOptions.length}</span>
//             </div>
              
//           </Col>
//           {/* next button */}
//           <Col className="file-manager__filters__rows d-flex justify-content-end" md="4">
//             <Button 
//             theme="primary"
//             style={{width: '10rem'}}
//             disabled={!canNextPage}
//             onClick={(e) => nextPage()}
//             >Next</Button>
//           </Col>
//           </Row>
//         </Container>
//       </CardFooter>}