import axios from 'axios'
import url from '../../connection/url'
import store from '../store'

export const LOGIN_BEGIN = "LOGIN_BEGIN"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"

export const LOGOUT_USER = "LOGOUT_USER"

export const UPDATE_LOGIN_FORM = "UPDATE_LOGIN_FORM"

export const AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS"
export const AUTHENTICATE_FAILURE = "AUTHENTICATE_FAILURE"

export const SET_AUTHZ_TIMEOUT = "SET_AUTHZ_TIMEOUT"

export const CLEAR_ERR = "CLEAR_ERR"

//PASSWORD RESET REQUEST
export const PW_RESET_REQUEST_BEGIN = "PW_RESET_REQUEST_BEGIN"
export const PW_RESET_REQUEST_SUCCESS = "PW_RESET_REQUEST_SUCCESS"
export const PW_RESET_REQUEST_FAILURE = "PW_RESET_REQUEST_FAILURE"

//PASSWORD UPDATE REQUEST
export const PW_UPDATE_REQUEST_BEGIN = "PW_UPDATE_REQUEST_BEGIN"
export const PW_UPDATE_REQUEST_SUCCESS = "PW_UPDATE_REQUEST_SUCCESS"
export const PW_UPDATE_REQUEST_FAILURE = "PW_UPDATE_REQUEST_FAILURE"

//PASSWORD RESET REQUEST
export const resetPasswordRequestBegin = () => ({
  type : PW_RESET_REQUEST_BEGIN
})
export const resetPasswordRequestSuccess = () => ({
  type: PW_RESET_REQUEST_SUCCESS
})
export const resetPasswordRequestFailure = (err) => ({
  type: PW_RESET_REQUEST_FAILURE,
  payload: err
})

//PASSWORD UPDATE REQUEST
export const updatePasswordRequestBegin = () => ({
  type: PW_UPDATE_REQUEST_BEGIN
})
export const updatePasswordRequestSuccess = () => ({
  type: PW_UPDATE_REQUEST_SUCCESS
})
export const updatePasswordRequestFailure = (err) => ({
  type: PW_UPDATE_REQUEST_FAILURE,
  payload: err
})

export const clearError = () => ({
  type: CLEAR_ERR
})

const _setAuthzTimer = (func) => {
  // console.log('setAuthTimer called')
  clearTimeout(store.getState().auth.auth_timeout)
  return {
    type: SET_AUTHZ_TIMEOUT,
    payload: func
  }
}

const authenticateSuccess = USR => ({
  type: AUTHENTICATE_SUCCESS,
  payload: USR
})

const authenticateFailure = () => ({ type: AUTHENTICATE_FAILURE })

const loginBegin = () => ({ type: LOGIN_BEGIN })

const loginSuccess = USR => ({
  type: LOGIN_SUCCESS,
  payload: USR
})

const loginFailure = ERR => ({
  type: LOGIN_FAILURE,
  payload: ERR
})

export const updateLoginForm = AUTH => ({
  type: UPDATE_LOGIN_FORM,
  payload: AUTH
})

export const logout = () => ({
  type: LOGOUT_USER
})


export const setAuthzTimer = () => {
  // console.log("setting authz timer")
  
  return async dispatch => {
    const check = await check_authz()
    if(!check) {
     
      dispatch(logoutUser())
    }else {
      let timer = setTimeout(async () => {
        
        const check = await check_authz()
        if(!check) {
         
          dispatch(logoutUser())
        }
      },(61000 * 60)) // 1 hour and 1 minute.
      dispatch(_setAuthzTimer(timer))
    }
  }
}

export const logoutUser = () => {
  // console.log('logout user')
  return async dispatch => {
    const response = await axios({
      baseURL: `${url}/logout`,
      withCredentials: true,
      credentials: 'same-origin',
      method: 'get',
    })
    if(response.data.LOGOUT || (response.data.ERR === 'INV_LOGIN')) {
      // console.log('logout called.')
      dispatch(logout())
    //   // sessionStorage.removeItem('state--')
    }else {
      //error

    }
  }
}

export const check_authz = async () => {
  const response = await axios({
    baseURL: `${url}/authz`,
    withCredentials: true,
    credentials: 'same-origin',
    method: 'get',
  })
  // console.log('response.data: ', response.data)
  if(response.data.ERR && response.data.ERR === 'INV_LOGIN') 
    return false
  else 
    return true
}

export const authz = () => {
  try {
    return async dispatch => {
      const response = await axios({
        baseURL: `${url}/authz`,
        withCredentials: true,
        credentials: 'same-origin',
        method: 'get',
      })
      if(response.data.AUTH) {
        dispatch(authenticateSuccess({
          ...response.data.AUTH.USR,
          password: '****'
        }))
      }else {
        // dispatch(logoutUser())
        // dispatch(authenticateFailure())
      }
        
    }
  }catch(err) {
    console.log('err: ', err)
  }
}

export const login = (email,pass) => {
  return async dispatch => {
    dispatch(loginBegin())
    const response = await axios({
      baseURL: `${url}/authw`,
      withCredentials: true,
      credentials: 'same-origin',
      method: 'post',
      data: {
        USR: {
          EML: email,
          PW: pass
        }
      }
    })
    if(response.data.AUTH) {
      dispatch(loginSuccess({
        ...response.data.AUTH.USR,
        password: '****'
      }))
    }else
      dispatch(loginFailure("Unable to authenticate. Incorrect Email or Password."))
    try {
      
    }catch(err) {
      console.log(err)
    }
  }
}

/**
 * 
 * resetPasswordRequest sends an email address to the server 
 * to be validated as registered, and if so, sends an email to
 * the specified address with instructions on how to reset their 
 * password.
 * 
 * @param {*} email 
 */
export const resetPasswordRequest = (email) => {
  return async dispatch => {
      dispatch(resetPasswordRequestBegin())
      const response = await axios({
          baseURL: `${url}/pwd`,
          crossDomain: true,
          timeout: 200000,
          credentials: 'same-origin',
          responseType: 'json',
          method: 'post',
          data: { EML: email }
      })
      if(response.data.ERR) {
          console.log('response.data.ERR: ', response.data.ERR)
          dispatch(resetPasswordRequestFailure(response.data.ERR))
      }else if(response.data.RSP !== null && response.data.ACK === 'true' || response.data.ACK === true) {
          dispatch(resetPasswordRequestSuccess())
      }
  }
}

/**
 * updatePasswordRequest sends the unique url identifier for the 
 * specified password request, as well as the new password to be 
 * set on the User's account tied with the PasswordReset request 
 * using the unique url identifer.
 * 
 * @param {*} password 
 * @param {*} urlId 
 */
export const updatePasswordRequest = (password,urlId) => {
  return async dispatch => {
      dispatch(updatePasswordRequestBegin())
      const response = await axios({
          baseURL: `${url}/pwd/upd`,
          crossDomain: true,
          timeout: 200000,
          credentials: 'same-origin',
          responseType: 'json',
          method: 'post',
          data: { URL: urlId, PASS: password }
      })
      if(response.data.ERR !== null) {
          dispatch(updatePasswordRequestFailure(response.data.ERR))
      }else if(response.data.RSP !== null && response.data.ACK === 'true' || response.data.ACK === true) {
          dispatch(updatePasswordRequestSuccess())
      }
  }
}