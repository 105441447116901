import store from '../store'

export const END_APP_LOADER = "END_APP_LOADER"
export const SET_SHOW_REQUEST = "SET_SHOW_REQUEST"

export const SET_SEARCH_TIMER = "SET_SEARCH_TIMER"
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP"
export const SET_HOME_TAB = "SET_HOME_TAB"

export const SET_SHOW_CONFIRMATION = "SET_SHOW_CONFIRMATION"
export const SET_HISTORY_SEARCH_TIMER = "SET_HISTORY_SEARCH_TIMER"
export const SET_NEXT_BUTTON_TIMER = "SET_NEXT_BUTTON_TIMER"

export const SET_STEP_2_VALID = "SET_STEP_2_VALID"


export const setStep2Valid = (bool) => ({
  type: SET_STEP_2_VALID,
  payload: bool
})

export const setNextButtonTimer = (func) => {
  clearTimeout(store.getState().layout.nextButtonTimer)
  return {
    type: SET_NEXT_BUTTON_TIMER,
    payload: func
  }
}

export const setHistorySearchTimer = (func) => {
  clearTimeout(store.getState().layout.historySearchTimer)
  return {
    type: SET_HISTORY_SEARCH_TIMER,
    payload: func
  }
}

export const setShowConfirmation = (bool) => ({
  type: SET_SHOW_CONFIRMATION,
  payload: bool
})

export const setActiveStep = (step) => ({
  type: SET_ACTIVE_STEP,
  payload: step
})

export const setHomeTab = (tab) => ({
  type: SET_HOME_TAB,
  payload: tab
})

export const endLoader = () => ({ type: END_APP_LOADER })

export const setShowRequest = (bool) => ({
  type: SET_SHOW_REQUEST,
  payload: bool
})

export const setSearchTimer = (timer) => ({
  type: SET_SEARCH_TIMER,
  payload: timer
})