import { createStore, applyMiddleware } from "redux"
import rootReducer from "./rootReducer"
import thunk from 'redux-thunk'
import throttle from 'lodash.throttle'
import { saveReduxState, loadReduxState } from './storeHelpers'

const persistedState = loadReduxState()

const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(thunk)
)

store.subscribe(throttle(() => {
  saveReduxState({
    newRequest: store.getState().newRequest,
    layout: store.getState().layout,
    // history: store.getState().history,
    auth: store.getState().auth
  })
},1000))

export default store