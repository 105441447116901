import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'


//CREATED TO OVERRIDE THE DEFAULT ROUTE TO ENABLE AUTHENTICATION AND REDIRECTING TO LOGIN PAGE
//SHOULD THE USER NOT BE LOGGED IN.

const PrivateRoute = ({auth,component: Component, ...rest}) => {
    useEffect(() => {
        // console.log('private route update')
    })
    return(
    <Route {...rest} render={(props) => (
        (!auth ? props.location.pathname !== '/login' ? props.location.pathname !== '/reset-password-request' ? !props.location.pathname.includes('update-password') : false : false : false)
        ? <Redirect to='/login' />
        : <Component {...props}/>
    )} />)
    }


export  default PrivateRoute