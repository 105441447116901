import React, { useEffect,useState } from 'react'
import { connect} from 'react-redux'
import styled from 'styled-components'

import { Toast } from 'react-bootstrap'
import { Row,Col } from 'shards-react'

import { clearError } from '../../redux/actions/newRequestActions'

const Styled_Close = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  font-weight: 500;
  text-shadow: none;
  color: inherit;
  line-height: 1;
  &:hover {
    cursor: pointer;
    color: #8c949d;
  }
`


const mapDispatchToProps = dispatch => ({
  clearError: () => dispatch(clearError())
})

const mapStateToProps = state => ({
  requestError: state.newRequest.error
})

export default connect(mapStateToProps,mapDispatchToProps)((props) => {

  // const [show,setShow] = useState(true)
  console.log('error: ', props.error)

  return (
    <div className="d-flex w-100 justify-content-center esign-error row">
      <div className="justify-content-center align-content-center ml-3 mr-3" style={{float: 'left'}}>
        <div className="alert alert-danger alert-dismissible fade show pl-5 pr-5" role="alert">
          <div className="d-flex align-items-center">
            <span className="d-flex align-items-center pr-3" style={{textAlign: 'center'}}>{props.error}
            </span>
            <Styled_Close className="" type="button" aria-label="Close" onClick={(e) => {
              // props.clearError()
              props.set(false)
              // props.clearError()
              }}>
              x
            </Styled_Close>
          </div>
          
        </div>
      </div>
    </div>
  )
})