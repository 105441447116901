import React, { useEffect,useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { addClient,removeClient, updateClientMasterListLocal } from '../../redux/actions/newRequestActions'
import selectClients from './selectClients'

const Div = styled.div`
  opacity: ${props => props.disabled ? '0.6' : '1'}; 
  :hover {
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    opacity: ${props => props.disabled ? '0.6' : '1'};
    color: #0133b6;
    overflow: hidden;
    padding-left: none;
    /* width: 75%; */
  }
` 

const Input = styled.input`
  :hover {
    cursor: pointer;
  }
  margin-right: .2em;
  vertical-align: middle;
`

const Span = styled.span`
  width: 100%;
  padding-left: .5em;
`

export const mapStateToProps = state => ({
  selectedClients: state.newRequest.selectedClients,
  clients: state.newRequest.clients
})

export const mapDispatchToProps = dispatch => ({
  addClient: (client) => dispatch(addClient(client)),
  removeClient: (client) => dispatch(removeClient(client)),
  updateClient: (client) => dispatch(updateClientMasterListLocal(client))
})

export default connect(mapStateToProps,mapDispatchToProps)(({addClient,removeClient,client,selectedClients,results,disabled,updateClient,checkAll,clients}) => {

  const [checked,setChecked] = useState(false)

  // console.log('client: ', client)
  useEffect(() => {
    // if(selectedClients && selectedClients.length > 0) {
    //   let _client = selectedClients.find((_client) => _client.Id === client.Id)
    //   if(_client) {
    //     setChecked(true)
    //   }
    // }
    let searchedClient = clients.find((c) => c.Id === (client.item ? client.item.Id : client.Id))
    setChecked(client.checked || searchedClient && searchedClient.checked)

    // if(!client.checked && checkAll)  updateClient({...client,checked:true})

    // if(client.)
    // if(selectedClients && selectedClients.length > 0) {
    //   // console.log('selected clients: ', selectedClients, ' client: ', client)
    //   for(let _client in selectedClients) {
    //     if(selectedClients[_client].Id === client.Id) {
    //       // console.log('selectedClient: ', selectedClients[_client].Id, ' client: ', client.Id)
    //       setChecked(true)
    //       break
    //     }
    //   }
    // }
    
    // console.log('checked: ', checked)
  },[client,checkAll])

  return (
    <Div
      disabled={disabled}
      className="d-flex justify-content-center"  
      // onClick={(e) => {
      //   if(!disabled) {
      //     if(!checked) 
      //       addClient(client)
      //     else if (checked === true)
      //       removeClient(client)

      //     setChecked(!checked)
      //   }
        
      // }}
    >
      {/* <Span className="d-flex align-items-center justify-content-start"> */}
      <Input
        type="checkbox"
        checked={checked}
        onChange={(e)=>{
          let checked = e.target.checked
          let _c = (clients.find((c) => c.Id === (client.item ? client.item.Id : client.Id)))
          updateClient({..._c,checked:checked})
          setChecked(checked)
        }}
      />
        {/* {client.name}</Span> */}
    </Div>
  )
})