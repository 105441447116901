import Axios from 'axios'
import url from '../../connection/url'
import { logoutUser } from '../actions/authActions'

export const GET_SIG_LIST_BEGIN = "GET_SIG_LIST_BEGIN"
export const GET_SIG_LIST_SUCCESS = "GET_SIG_LIST_SUCCESS"
export const GET_SIG_LIST_FAILURE = "GET_SIG_LIST_FAILURE"

export const SET_HISTORY_ERR = "SET_HISTORY_ERR"

export const CANCEL_INCOMPLETE_SIG_BEGIN = "CANCEL_INCOMPLETE_SIG_BEGIN"
export const CANCEL_INCOMPLETE_SIG_SUCCESS = "CANCEL_INCOMPLETE_SIG_SUCCESS"
export const CANCEL_INCOMPLETE_SIG_FAILURE = "CANCEL_INCOMPLETE_SIG_FAILURE"

export const UPDATE_CUSTOMER_EMAIL_BEGIN = "UPDATE_CUSTOMER_EMAIL_BEGIN"
export const UPDATE_CUSTOMER_EMAIL_SUCCESS = "UPDATE_CUSTOMER_EMAIL_SUCCESS"
export const UPDATE_CUSTOMER_EMAIL_FAILURE = "UPDATE_CUSTOMER_EMAIL_FAILURE"

export const GET_TEMPLATE_LIST_FILTER_BEGIN = "GET_TEMPLATE_LIST_FILTER_BEGIN"
export const GET_TEMPLATE_LIST_FILTER_SUCCESS = "GET_TEMPLATE_LIST_FILTER_SUCCESS"
export const GET_TEMPLATE_LIST_FILTER_FAILURE = "GET_TEMPLATE_LIST_FILTER_FAILURE"

export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT"

export const UPDATE_SIG_LIST_PARAMS = "UPDATE_SIG_LIST_PARAMS"

export const RESET_SIG_LIST_PARAMS = "RESET_SIG_LIST_PARAMS"


export const resetSigListParams = () => ({
  type: RESET_SIG_LIST_PARAMS
})

export const setSearchText = (text) => ({
  type: SET_SEARCH_TEXT,
  payload: text
})

export const getTemplateListFilterBegin = () => ({
  type: GET_TEMPLATE_LIST_FILTER_BEGIN
})

export const getTemplateListFilterSuccess = (data) => ({
  type: GET_TEMPLATE_LIST_FILTER_SUCCESS,
  payload: data
})

export const getTemplateListFilterFailure= (err) => ({
  type: GET_TEMPLATE_LIST_FILTER_FAILURE,
  payload: err
})

export const updateSignatureListPrams = (params) => ({
  type: UPDATE_SIG_LIST_PARAMS,
  payload: params
})

export const updateCustomerEmailBegin = () => ({
  type: UPDATE_CUSTOMER_EMAIL_BEGIN
})

export const updateCustomerEmailSuccess = () => ({
  type: UPDATE_CUSTOMER_EMAIL_SUCCESS
})

export const updateCustomerEmailFailure = (err) => ({
  type: UPDATE_CUSTOMER_EMAIL_FAILURE,
  payload: err
})

export const cancelIncompleteSignatureRequestBegin = () => ({
  type: CANCEL_INCOMPLETE_SIG_BEGIN
})

export const cancelIncompleteSignatureRequestSuccess = () => ({
  type: CANCEL_INCOMPLETE_SIG_SUCCESS
})

export const cancelIncompleteSignatureRequestFailure = (err) => ({
  type: CANCEL_INCOMPLETE_SIG_FAILURE,
  payload: err
})

export const setHistoryErr = (err) => ({
  type: SET_HISTORY_ERR,
  payload: err
})

export const getSignatureListBegin = (params) => ({
  type: GET_SIG_LIST_BEGIN,
  payload: params
})

export const getSignatureListSuccess = (data) => ({
  type: GET_SIG_LIST_SUCCESS,
  payload: data
})

export const getSignatureListFailure = (err) => ({
  type: GET_SIG_LIST_FAILURE,
  payload: err
})


export const getSignatureList = (ID) => {
  return async dispatch => {
    try {
      let params = {}
      if(ID && Object.keys(ID).length > 0) {
        for(let i in Object.keys(ID)) {
          params = {
            ...params,
            [Object.keys(ID)[i]]:ID[Object.keys(ID)[i]]
          }
        }
      }
      dispatch(getSignatureListBegin(params))
      let response = await Axios({
        baseURL: `${url}/hellosign/signature-list`,
        withCredentials: true,
        credentials: 'same-origin',
        method: 'post',
        data: params
      })
      if(response.data) {
        if(response.data.LIST) {
          dispatch(getSignatureListSuccess(response.data.LIST))
        }else if(response.data.ERR) {
          if(response.data.ERR === "INV_LOGIN") 
            dispatch(logoutUser())
          dispatch(getSignatureListFailure('Unable to get SignatureRequest List. If problem persists, please contact support.'))
        }
      }else {
        dispatch(getSignatureListFailure('Unable to get SignatureRequest List. If problem persists, please contact support.'))
      }
    }catch(err) {
      console.log('getSignatureList Error: ', err)
      dispatch(setHistoryErr('Unable to get SignatureRequest List. If problem persists, please contact support.'))
    }
  }
}

export const cancelIncompleteSignatureRequest = (sig_id) => {
  return async dispatch => {
    try {
      if(sig_id) {
        dispatch(cancelIncompleteSignatureRequestBegin())
        let response = await Axios({
          baseURL: `${url}/hellosign/signature-request/${sig_id}/cancel`,
          withCredentials: true,
          credentials: 'same-origin',
          method: 'get'
        })
        if(response.data) {
          if(response.data.ACK) {
            dispatch(cancelIncompleteSignatureRequestSuccess())
          }else {
            dispatch(cancelIncompleteSignatureRequestFailure('Unable to cancel Signature Request. If problem persists, please contact support.'))
          }
        }else {
          dispatch(cancelIncompleteSignatureRequestFailure('Unable to cancel Signature Request. If problem persists, please contact support.'))
        }
      }
    }catch(err) {
      dispatch(setHistoryErr('Unable to cancel Signature Request. If problem persists, please contact support.'))
    }
  }
}

export const updateCustomerEmail = (ID) => {

  return async dispatch => {
    try {
      if(ID.Id && ID.customerEmail && ID.sig_id) {
        let response = await Axios({
          baseURL: `${url}/hellosign/signature-request/${ID.sig_req_id}/signature/${ID.sig_id}/update-email`,
          withCredentials: true,
          credentials: 'same-origin',
          method: 'post',
          data: {ACCT: {
            Id: ID.Id,
            customerEmail: ID.customerEmail
          }}
        })
        if(response.data) {
          if(response.data.ACK) {
            dispatch(updateCustomerEmailSuccess())
          }else {
            dispatch(updateCustomerEmailFailure("Unable to update the Customer's Email. If problem persists, please contact support."))
            console.log('updateCustomerEmail error code: ', response.data.ERR)
          }
        }else {
          dispatch(updateCustomerEmailFailure("Unable to update the Customer's Email. If problem persists, please contact support."))
        }
      }
    }catch(err) {
      dispatch(setHistoryErr("Unable to update the Customer's Email. If problem persists, please contact support."))
    }
  }
}

export const getTemplateListFilters = () => {
  return async dispatch => {
    try {
      dispatch(getTemplateListFilterBegin())
      const response = await Axios({
        baseURL: `${url}/hellosign/template/list`,
        withCredentials: true,
        credentials: 'same-origin',
        method: 'get',
      })
      if(response.data) {
        dispatch(getTemplateListFilterSuccess(response.data.templates))
        // console.log('response: ', response)
      }else
        dispatch(getTemplateListFilterFailure('Unable to get list of Agreements for Filtering. If problem persists, please contact support.'))
      // dispatch(getTemplateListFailure('Unable to get list of Templates for signing request. If problem persists, please contact support.'))
    }catch(error) {
      console.log('getTemplateListFilter error: ', error)
      dispatch(setHistoryErr('Unable to get list of Agreements for Filtering. If problem persists, please contact support.'))
    }
  }
}
