import React, { useEffect, useState } from 'react'
import { Row } from 'shards-react'
import HistoryTable from './viewHistoryTable'
import { connect } from 'react-redux'

import { getSignatureList } from '../../redux/actions/historyActions'
import { setAuthzTimer,authz } from '../../redux/actions/authActions'

const mapStateToProps = state => ({
  signatureList: state.history.signatureList,
  error: state.history.error
})

const mapDispatchToProps = dispatch => ({
  getSigList: (ID) => dispatch(getSignatureList(ID)),
  setAuthTimer: () => dispatch(setAuthzTimer())
})

export default connect(mapStateToProps,mapDispatchToProps)(({signatureList,error,getSigList,setAuthTimer}) => {

useEffect(() => {
  
  setAuthTimer()
  
},[signatureList,error])

  return (
    <div className="h-100">
      <Row noGutters className="pb-2 pt-0  d-flex justify-content-center">
        <HistoryTable />
      </Row>
    </div>
  )
})