import { authState } from '../initialState'

import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  UPDATE_LOGIN_FORM,
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_FAILURE,
  LOGOUT_USER,
  SET_AUTHZ_TIMEOUT,
  PW_RESET_REQUEST_BEGIN,
  PW_RESET_REQUEST_SUCCESS,
  PW_RESET_REQUEST_FAILURE,

  PW_UPDATE_REQUEST_BEGIN,
  PW_UPDATE_REQUEST_SUCCESS,
  PW_UPDATE_REQUEST_FAILURE,

  CLEAR_ERR,

} from '../actions/authActions'


export default (state = authState,action) => {
  // console.log('auth reducer: ', action)
  switch(action.type) {
    case UPDATE_LOGIN_FORM: 
      return {
        ...state,
        AUTH: {...action.payload},
        error: undefined
      }
    case LOGIN_BEGIN: 
      return {
        ...state,
        AUTH: {
          ...state.AUTH,
          loading: true
        }
      }
    case LOGIN_SUCCESS: 
      return {
        ...state,
        AUTH: {
          ...state.AUTH,
          loading: false,
          USR: {...action.payload},
          authenticated: true
        }
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        AUTH: {
          ...state.AUTH,
          loading: false,
          USR: false,
          authenticated: false
        },
        error: action.payload
      }
    case AUTHENTICATE_SUCCESS:
      return {
        ...state,
        AUTH: {
          ...state.AUTH,
          USR: {...action.payload},
          authenticated: true
        }
      }
    case AUTHENTICATE_FAILURE:
      return {
        ...state,
        AUTH: {
          ...state.AUTH,
          USR: false,
          authenticated: false
        }
      }
    case LOGOUT_USER:
      return {
        ...state,
        AUTH: {}
      }
    case SET_AUTHZ_TIMEOUT:
      return {
        ...state,
        auth_timeout: action.payload
      }
    case CLEAR_ERR:
        return {
          ...state,
          error: null,
          loading: false
        }
    case PW_RESET_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case PW_RESET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }
    case PW_RESET_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case PW_UPDATE_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case PW_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }
    case PW_UPDATE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return {...state}
  }
}