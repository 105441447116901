
import { layoutState } from '../initialState'
import { END_APP_LOADER, SET_SHOW_REQUEST, SET_SEARCH_TIMER, SET_ACTIVE_STEP, SET_SHOW_CONFIRMATION, SET_HISTORY_SEARCH_TIMER, SET_NEXT_BUTTON_TIMER, SET_STEP_2_VALID } from '../actions/layoutActions'


export default(state = layoutState,action) => {
  switch(action.type) {
    case END_APP_LOADER:
      return {
        ...state,
        appLoader: true
      }
    case SET_SHOW_REQUEST:
      return {
        ...state,
        showRequest: action.payload
      }
    case SET_SEARCH_TIMER:
      return {
        ...state,
        timer: action.payload
      }
    case SET_ACTIVE_STEP:
      return {
        ...state,
        step: action.payload
      }
    case SET_SHOW_CONFIRMATION:
      return {
        ...state,
        showConfirmation: action.payload
      }
    case SET_HISTORY_SEARCH_TIMER:
      return {
        ...state,
        historySearchTimer: action.payload
      }
    case SET_NEXT_BUTTON_TIMER:
      return {
        ...state,
        nextButtonTimer: action.payload
      }
    case SET_STEP_2_VALID:
      return {
        ...state,
        step2Valid: action.payload
      }
    default:
      return {
        ...state
      }
  }
}