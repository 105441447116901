/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { Link,Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button
} from "shards-react";
import { connect } from "react-redux";
import { updateLoginForm, login } from '../redux/actions/authActions'

import styled from 'styled-components'
import background from '../images/backgrounds/background-1.jpg'

const H6 = styled.h6`
  color: red;
  text-align: center;
`

const StyledImg = styled.img`
  max-width: 13rem !important;
  max-height: 13rem !important;
`


const mapStateToProps = state => ({
  AUTH: state.auth.AUTH,
  error: state.auth.error
})

const mapDispatchToProps = dispatch => ({
  update: (AUTH) => dispatch(updateLoginForm(AUTH)),
  login: (email,pass) => dispatch(login(email,pass))
})

export default connect(mapStateToProps,mapDispatchToProps)(({AUTH,login,update,error}) => (
  (AUTH.authenticated ? <Redirect to="/" /> :
  <Container fluid 
  onKeyPress={(e) => {
    if(e.charCode === 13) 
      login(AUTH.email,AUTH.pass)
  }}
  className="main-content-container h-75 px-4" 
    style={{ backgroundImage: `url(${background})`}}
  >
    <Row noGutters className="h-100">
      <Col lg="3" md="5" className="auth-form mx-auto my-auto">
        <Card>
          <CardBody>
            {/* Logo */}
            <StyledImg
              className="auth-form__logo d-table mx-auto m-0 pb-4"
              src={require('../../src/images/avatars/GSS_Logo_Vertical_Green.png')}
              style={{width: '35% !important'}}
              alt="GSS E-Sign"
            />

            {/* Title */}
            <h4 className=" text-center mb-4">
              Contracts Dashboard
            </h4>

            {error && <H6>{error}</H6>}

            {/* Form Fields */}
            <Form>
              <FormGroup>
                <label className="form-input-label" htmlFor="exampleInputEmail1">Email address</label>
                <FormInput
                  type="email"
                  id="exampleInputEmail1"
                  placeholder="Enter email"
                  autoComplete="email"
                  style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
                  onChange={((e) => {update({
                    ...AUTH,
                    email: e.target.value
                  })})}
                />
              </FormGroup>
              <FormGroup>
                <label className="form-input-label" htmlFor="exampleInputPassword1">Password</label>
                <FormInput
                  type="password"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  autoComplete="current-password"
                  style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
                  onChange={((e) => {update({
                    ...AUTH,
                    pass: e.target.value
                  })})}
                />
              </FormGroup>
              {/* <FormGroup>
                <FormCheckbox>Remember me for 30 days.</FormCheckbox>
              </FormGroup> */}
              <Button
                // pill
                theme="primary"
                className="d-table mx-auto"
                onClick={(e) => login(AUTH.email,AUTH.pass)}
              >
                Login
              </Button>
              {/* <FormGroup>
                <Link 
                className="d-table mx-auto my-2"
                to="/forgot-password" >Forgot Password</Link>
              </FormGroup> */}
              <div className="auth-form__meta d-flex mt-3 justify-content-center">
                <Link to="/reset-password-request">Forgot your password?</Link>
                {/* <Link to="/register" className="ml-auto">
                  Create a new account?
                </Link> */}
              </div> 
            </Form>
          </CardBody>

          {/* Social Icons */}
          {/* <CardFooter>
            <ul className="auth-form__social-icons d-table mx-auto">
              <li>
                <a href="#">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-github" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-google-plus-g" />
                </a>
              </li>
            </ul>
          </CardFooter> */}
        </Card>

        {/* Meta Details */}
      </Col>
    </Row>
  </Container>)
))
