import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { connect } from "react-redux";
import styled from 'styled-components'

import { logoutUser } from '../../../../redux/actions/authActions'

const Styled_A = styled.a`
  color: #f26b0e !important;
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    if(this.props.USR) {
      this.setState({
        visible: !this.state.visible
      });
    }
  }

  render() {
    // return (
    //   <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
    //     <DropdownToggle tag={NavLink} className="text-nowrap px-3">
    //       {/* <img
    //         className="user-avatar rounded-circle mr-2"
    //         src={require("./../../../../images/avatars/user_male2-512.png")}
    //         alt="User Avatar"
    //       />{" "} */}
    //     <span style={{color: 'white'}} className="d-none d-md-inline-block">{this.props.USR && (this.props.USR.firstName && this.props.USR.firstName + ' ' + this.props.USR.lastName)}</span>
    //     </DropdownToggle>
    //     <Collapse tag={DropdownMenu} right small open={this.state.visible}>
    //       {/* <DropdownItem tag={Link} to="user-profile">
    //         <i className="material-icons">&#xE7FD;</i> Profile
    //       </DropdownItem>
    //       <DropdownItem tag={Link} to="edit-user-profile">
    //         <i className="material-icons">&#xE8B8;</i> Edit Profile
    //       </DropdownItem>
    //       <DropdownItem tag={Link} to="file-manager-list">
    //         <i className="material-icons">&#xE2C7;</i> Files
    //       </DropdownItem>
    //       <DropdownItem tag={Link} to="transaction-history">
    //         <i className="material-icons">&#xE896;</i> Transactions
    //       </DropdownItem>
    //       <DropdownItem divider /> */}
    //       <DropdownItem onClick={(e) => {
    //         this.props.logout()
    //       }} className="text-danger">
    //         <i className="material-icons text-danger">&#xE879;</i> Logout
    //       </DropdownItem>
    //     </Collapse>
    //   </NavItem>
    // );

    return (
      <div className="d-flex align-items-center justify-content-end mr-3">
        <span>{this.props.USR && (this.props.USR.firstName && this.props.USR.firstName + ' ' + this.props.USR.lastName)}</span>
        <Styled_A
          className="ml-3" 
          onClick={(e) => this.props.logout()}><span>Logout</span></Styled_A>
      </div>
    )
  }
}

export const mapStateToProps = state => ({
  USR: state.auth.AUTH.USR
})

export const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutUser())
})

export default connect(mapStateToProps,mapDispatchToProps)(UserActions)
