import React, { useEffect } from "react";
import { HashRouter as Router, Route } from "react-router-dom";

import { connect} from 'react-redux'

import PrivateRoute from './PrivateRoute'
import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.scss";

import { authz,logoutUser } from './redux/actions/authActions'
import { endLoader } from './redux/actions/layoutActions'

import { Col,Row } from 'shards-react'
import ReactLoading from 'react-loading'


const mapStateToProps = state => ({
  authenticated: state.auth.AUTH.authenticated,
  appLoader: state.layout.appLoader,

})

const mapDispatchToProps = dispatch => ({
  auth: () => dispatch(authz()),
  endLoader: () => dispatch(endLoader()),
  deauth: () => dispatch(logoutUser())
})

export default connect(mapStateToProps,mapDispatchToProps)((props) => {
  
  useEffect(() => {
    
    props.auth()

    // console.log('app update')
    setTimeout(() => props.endLoader(), 1500)
  })

  return (
    (!props.appLoader 
    ?
    <Col style={{position: 'fixed', top: '30%'}}>
      <Row className="d-flex justify-content-center">
        <ReactLoading type={'spin'} color={'#0133b6'} width={'15%'} height={'15%'} />
      </Row>
    </Col>
    :
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <div>
        {routes.map((route, index) => {
          return (
            <PrivateRoute
              auth={props.authenticated}
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker(props => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                )
              })}
            />
          )
        })}
      </div>
    </Router>))
})
