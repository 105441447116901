import React, { useEffect,useState,Fragment } from 'react'
import { Card,CardHeader,CardBody,FormInput,FormTextarea,Col,Form,Button } from 'shards-react'
import Modal from 'react-bootstrap/Modal'
import { connect } from 'react-redux'
import { updateRequest, sendSigningRequest } from '../../redux/actions/newRequestActions'
import { setShowConfirmation, setActiveStep } from '../../redux/actions/layoutActions'
import { setAuthzTimer,authz } from '../../redux/actions/authActions'


const mapStateToProps = state => ({
  selectedTemplate: state.newRequest.selectedTemplate,
  mapper: state.newRequest.templateMapper,
  request: state.newRequest.REQUEST,
  auth: state.auth,
  newRequest: state.newRequest,
  showConfirmation: state.layout.showConfirmation,
  activeStep: state.layout.step
})

const mapDispatchToProps = dispatch => ({
  updateRequest: (req) => dispatch(updateRequest(req)),
  setShow: (bool) => dispatch(setShowConfirmation(bool)),
  setActiveStep: (step) => dispatch(setActiveStep(step)),
  sendRequest: (r,a) => dispatch(sendSigningRequest(r,a)),
  setAuthTimer: () => dispatch(setAuthzTimer())
})

export default connect(mapStateToProps,mapDispatchToProps)
(({updateRequest,selectedTemplate,mapper,request,newRequest,auth,activeStep,showConfirmation,setShow,setActiveStep,sendRequest,setAuthTimer}) => {

  const subject = 'Global Shop Software Licensing Agreement for Signature'

  const message = (mapper.message ? mapper.message : 'Welcome to Global Shop Solutions. We are excited you have chosen Global Shop for your ERP software and we look forward to working with you. \n' +  
  '\n' +
  'The link in this e-mail will take you to our software licensing agreement. Please review the agreement and sign it electronically. \n' +
  '\n' +
  'If you have any questions, please feel free to contact us at licensing@gssmail.com.')

  const warningMessage = "Please review the details of your signature request to confirm the selected template, customer(s) and contact information is correct."

  const modalMessage = 'Confirming that you would like to send the selected template to the selected customers using the entered subject line and body?'

  useEffect(() => {
    setAuthTimer()
    if(!request || (!request.subject || !request.message)) {
      updateRequest({
        ...request,
        subject: subject,
        message: message
      })
    }
  },[request])

  return (
    <div>
      <Card className="d-flex align-items-stretch" style={{width: '95vw'}}>
        <CardHeader className="d-flex justify-content-center align-items-center pb-2">
          <h4 className="m-0">Enter Subject Line and Message for Signature Request</h4>
          
          {/* <div className="d-flex align-items-center p-0 pl-3" style={{width: '2.3rem', height: '2.3rem'}} ></div>   */}
          
        </CardHeader>
        <CardBody className="d-flex justify-content-center align-items-center" >
          <Col lg="12" style={{minHeight: '50vh'}} className="p-0">
            <div className="d-flex justify-content-center">
              {/* <p
                style={{fontSize: '16px'}}
              >{warningMessage}</p> */}
            </div>
            <Form>
              <label className="form-input-label">Subject</label>
              <FormInput 
                // style={{border: 'solid .12rem #e1e5eb'}}
                defaultValue={request ? request.subject : subject}
                onChange={(e) => {
                  updateRequest({
                  ...request,
                  subject: e.target.value
                })}}
                style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
                className="mb-2"
                placeholder="Add an Email Subject"
              />
              <label className="form-input-label mt-1">Message</label>
              <FormTextarea 
                value={request ? request.message : (mapper.message ? mapper.message : message)}
                // dangerouslySetInnerHTML={{_html: request ? request.message : (mapper.message ? mapper.message : message)}}
                className=""
                onChange={(e) => {updateRequest({
                  ...request,
                  message: e.target.value
                })}}
                rows={9}
                style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px', resize: 'none'}}
                placeholder="Add a Message to Your Customers"
              />
              {/* <div class="form-control pb-5" 
              rows={8}
              style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px', resize: 'none'}}
              // dangerouslySetInnerHTML={{__html: mapper.message}}
              >{message}</div> */}
            </Form>
          </Col>
        </CardBody>
      </Card>
      <Modal 
      show={showConfirmation}
      onHide={(e) => setShow(false)}
      >
        <Modal.Header closeButton><h4>Send Request</h4></Modal.Header>
        <Modal.Body>
          <h5>
            {modalMessage}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button 
          // style={{width: '7.5rem'}}
          theme="primary"
          onClick={(e) => {
            setShow(false)
            sendRequest(newRequest,auth)
            setActiveStep(activeStep + 1)
          }}
          >Yes, Send</Button>
          <Button 
          // style={{width: ''}}
          theme="primary"
          onClick={(e) => {
            setShow(false)
          }}
          >No, go back</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
})