import { historyState } from '../initialState'
import { 
  GET_SIG_LIST_BEGIN, 
  GET_SIG_LIST_SUCCESS, 
  GET_SIG_LIST_FAILURE, 
  SET_HISTORY_ERR, 
  CANCEL_INCOMPLETE_SIG_BEGIN,
  CANCEL_INCOMPLETE_SIG_SUCCESS,
  CANCEL_INCOMPLETE_SIG_FAILURE,
  UPDATE_CUSTOMER_EMAIL_BEGIN,
  UPDATE_CUSTOMER_EMAIL_SUCCESS,
  UPDATE_CUSTOMER_EMAIL_FAILURE,
  UPDATE_SIG_LIST_PARAMS,
  RESET_SIG_LIST_PARAMS,
  GET_TEMPLATE_LIST_FILTER_BEGIN,
  GET_TEMPLATE_LIST_FILTER_SUCCESS,
  GET_TEMPLATE_LIST_FILTER_FAILURE,
  SET_SEARCH_TEXT
} from '../actions/historyActions'


export default (state = historyState,action) => {
  // console.log("history reducer: ", action)
  switch(action.type) {
    case GET_SIG_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        error: false,
        params: action.payload,
        cancel: false,
        update: false,
        updateParams: false
      }
    case GET_SIG_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        signatureList: action.payload
      }
    case GET_SIG_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        signatureList: false
      }
    case SET_HISTORY_ERR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case CANCEL_INCOMPLETE_SIG_BEGIN:
      return {
        ...state,
        error: false
      }
    case CANCEL_INCOMPLETE_SIG_SUCCESS:
      return {
        ...state,
        error: false,
        cancel: true
      }
    case CANCEL_INCOMPLETE_SIG_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case UPDATE_CUSTOMER_EMAIL_BEGIN:
      return {
        ...state,
        error: false,
        loading: true
      }
    case UPDATE_CUSTOMER_EMAIL_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        update: true
      }
    case UPDATE_CUSTOMER_EMAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case UPDATE_SIG_LIST_PARAMS:
      return {
        ...state,
        error: false,
        loading: false,
        params: action.payload,
        updateParams: true
      }
    case RESET_SIG_LIST_PARAMS: 
      return {
        ...state,
        params: {}
      }
    case GET_TEMPLATE_LIST_FILTER_BEGIN:
      return {
        ...state,
        error: false
      }
    case GET_TEMPLATE_LIST_FILTER_SUCCESS:
      return {
        ...state,
        error: false,
        templateFilters: action.payload
      }
    case GET_TEMPLATE_LIST_FILTER_FAILURE:
      return {
        ...state,
        error: action.payload,
        templateFilters: false
      }
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload
      }
    default:
      return {...state}
  }
}