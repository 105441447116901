import React, { useEffect,useState } from 'react'
import { setAuthzTimer,authz } from '../../redux/actions/authActions'

import ReactTable from 'react-table-6'
import Modal from 'react-bootstrap/Modal'
import { Card,CardHeader,CardBody,Col,Button } from 'shards-react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ConfirmationReactTable from './confirmDetailsReactTable'

const mapStateToProps = state => ({
  report: state.newRequest.finalReport
})

const mapDispatchToProps = dispatch => ({
  setAuthTimer: () => dispatch(setAuthzTimer())
})

const Syled_Span = styled.span`
  font-size: 16px;
  font-weight: inherit;
  color: #212529;
`

export default connect(mapStateToProps,mapDispatchToProps)(({report,setAuthTimer}) => {

  const [show,setShow] = useState(true)

  useEffect(() => {
    setAuthTimer()
  })

  const columns = [{
    Header: props => <span style={{fontSize: '16px', fontWeight: '700'}}>Customer Company</span>,
    accessor: 'company',
    Cell: ({row}) => (<div className="d-flex align-items-center" style={{height: '46px'}}>
        <Syled_Span >{row.original.company}</Syled_Span>
      </div>)
  }, 
  {
    Header: props => <span style={{fontSize: '16px', fontWeight: '700'}}>Customer Name</span>,
    accessor: 'template',
    Cell: ({row}) => ( <div className="d-flex align-items-center" style={{height: '46px'}}><Syled_Span>{row.original.name}</Syled_Span></div> )
  },
  {
    Header: props => <span style={{fontSize: '16px', fontWeight: '700'}}>Customer Email</span>,
    accessor: 'email',
    Cell: ({row}) => ( <div className="d-flex align-items-center" style={{height: '46px'}}><Syled_Span>{row.original.email}</Syled_Span></div> )
  }]

  return (
    <Card className="d-flex align-items-stretch" style={{width: '95vw'}}>
      <CardHeader className="d-flex justify-content-center pb-2">
        
        <div className="text-center">
          <h4 className="m-0">Your Signature Request for the {report && report[0] && report[0].template} Has Been Sent to:</h4>
        </div>
      </CardHeader>
      <CardBody className="d-flex justify-content-center align-items-center" >
        <Col lg="12" style={{minHeight: '50vh'}}>
            {/* <div className="d-flex justify-content-center">
              <p
                style={{fontSize: '16px'}}
              >Signature Request Sent to the following Companies...</p>
            </div> */}
          {/* <ReactTable 
            style={{
              border: '.15rem solid #e1e5eb',
              'border-radius': '.25rem' 
            }}
            data={report}
            columns={columns}
            minRows={0}
            showPagination={report && report.length > 10}
            showPageSizeOptions={false}
            sortable={false}
            resizeable={false}
            filterable={false}
          /> */}
          {report && <ConfirmationReactTable
            data={report}
            columns={columns}
            setPageIndex={() => {}}
          />}
        </Col>
        
      </CardBody>
      {/* <Modal 
      show={show}
      onHide={(e) => setShow(false)}
      >
        <Modal.Header closeButton><h4>Confirmation</h4></Modal.Header>
        <Modal.Body>
          <h5>
            Signature Request Sent to the following Companies...
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button 
          style={{width: '7.5rem'}}
          theme="primary"
          onClick={(e) => {
            setShow(false)
          }}
          >Ok</Button>
        </Modal.Footer>
      </Modal> */}
    </Card>
  )
})