import React from "react";
import { Nav } from "shards-react";

import Notifications from "./Notifications";
import UserActions from "./UserActions";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  user: state.auth.AUTH.USR
})

export default connect(mapStateToProps)((props) => (
  <Nav navbar className="flex-row">
    {/* <Notifications /> */}
    {props.user && <UserActions />}
  </Nav>
))
