import React, { useEffect,useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { addClient,removeClient } from '../../redux/actions/newRequestActions'
import selectClients from './selectClients'

const Li = styled.li`
  cursor: ${props => props.disabled ? 'not-allowed' : 'inherit'};
  opacity: ${props => props.disabled ? '0.6' : '1'};
  :hover {
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    opacity: ${props => props.disabled ? '0.6' : '1'};
    color: #0133b6;
    overflow: hidden;
    padding-left: none;
    /* width: 75%; */
  }
` 

const Input = styled.input`
  :hover {
    cursor: pointer;
  }
  margin-right: .2em;
  vertical-align: middle;
`

const Span = styled.span`
  width: 100%;
  padding-left: .5em;
`

export const mapStateToProps = state => ({
  selectedClients: state.newRequest.selectedClients
})

export const mapDispatchToProps = dispatch => ({
  addClient: (client) => dispatch(addClient(client)),
  removeClient: (client) => dispatch(removeClient(client))
})

export default connect(mapStateToProps,mapDispatchToProps)(({addClient,removeClient,client,selectedClients,results,disabled}) => {

  const [checked,setChecked] = useState(false)

  // console.log('client: ', client)
  useEffect(() => {
    setChecked(false)
    if(selectedClients && selectedClients.length > 0) {
      // console.log('selected clients: ', selectedClients, ' client: ', client)
      for(let _client in selectedClients) {
        if(selectedClients[_client].Id === client.Id) {
          // console.log('selectedClient: ', selectedClients[_client].Id, ' client: ', client.Id)
          setChecked(true)
          break
        }
      }
    }
    // console.log('checked: ', checked)
  },[results,selectedClients])

  return (
    <Li
      disabled={disabled}
      className="d-flex justify-content-center"  
      onClick={(e) => {
        if(!disabled) {
          if(!checked) 
            addClient(client)
          else if (checked === true)
            removeClient(client)

          setChecked(!checked)
        }
        
      }}
    >
      <Span className="d-flex align-items-center justify-content-start">
      <Input
        type="checkbox"
        checked={checked}
        // onChange={()=>{}}
      />
        {client.name}</Span>
    </Li>
  )
})