import { newRequestState } from '../initialState'
import { 
  GET_CLIENT_LIST_BEGIN, 
  GET_CLIENT_LIST_SUCCESS, 
  GET_CLIENT_LIST_FAILURE, 
  SET_SELECTED_CLIENTS, 
  GET_TEMPLATE_LIST_BEGIN,
  GET_TEMPLATE_LIST_SUCCESS,
  GET_TEMPLATE_LIST_FAILURE,
  SET_SELECTED_TEMPLATE,
  UPDATE_REQUEST,
  CLEAR_ERROR,
  SET_SELECTED_TEMPLATE_MAPPER_BEGIN,
  SET_SELECTED_TEMPLATE_MAPPER_SUCCESS,
  SET_SELECTED_TEMPLATE_MAPPER_FAILURE,
  SET_NEW_REQUEST,
  SET_REQUEST_ERROR,
  BUILD_FINAL_REPORT,
  SET_CLIENT_SEARCH_RESULTS,
  SET_TEMPLATE_SEARCH_RESULTS,
  SET_CLIENT_SEARCHER,
  SET_TEMPLATE_SEARCHER,
  SET_CLIENT_CHECK_ALL,
  SET_CLIENT_SEARCH_TEXT,
  SET_TEMPLATE_SEARCH_TEXT,
  SET_CLIENT_SEARCH_TIMER
} from '../actions/newRequestActions'


export default (state = newRequestState,action) => {
  // console.log('newRequest reducer: ', action)
  switch(action.type) {
    case GET_CLIENT_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        error: false
      }
    case GET_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: action.payload,
        error: false
      }
    case GET_CLIENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case SET_SELECTED_CLIENTS:
      return {
        ...state,
        selectedClients: action.payload,
        error: false
      }
    case GET_TEMPLATE_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        error: false
      }
    case GET_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload,
        error: false
      }
    case GET_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        templates: undefined,
        error: action.payload
      }
    case SET_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload,
        error: false
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        REQUEST: {...action.payload},
        error: false
      }
    case CLEAR_ERROR:
      return {
        ...state,
        error: false
      }
    case SET_SELECTED_TEMPLATE_MAPPER_BEGIN:
      return {
        ...state,
        loading: true,
        error: false,
        templateMapper: false,
      }
    case SET_SELECTED_TEMPLATE_MAPPER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        templateMapper: action.payload,
        clients: undefined
      }
    case SET_SELECTED_TEMPLATE_MAPPER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        templateMapper: false
      }
    case SET_NEW_REQUEST:
      return {}
    case SET_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case BUILD_FINAL_REPORT:
      return {
        ...state,
        // error: false,
        finalReport: action.payload
      }
    case SET_CLIENT_SEARCH_RESULTS:
      return {
        ...state,
        clientSearchResults: action.payload
      }
    case SET_TEMPLATE_SEARCH_RESULTS:
      return {
        ...state,
        templateSearchResults: action.payload
      }
    case SET_CLIENT_SEARCHER:
      return {
        ...state,
        clientSearcher: action.payload
      }
    case SET_TEMPLATE_SEARCHER:
      return {
        ...state,
        templateSearcher: action.payload
      }
    case SET_CLIENT_CHECK_ALL:
      return {
        ...state,
        checkAll: action.payload
      }
    case SET_CLIENT_SEARCH_TEXT:
      return {
        ...state,
        clientSearchText: action.payload
      }
    case SET_TEMPLATE_SEARCH_TEXT:
      return {
        ...state,
        templateSearchText: action.payload
      }
    case SET_CLIENT_SEARCH_TIMER:
      return {
        ...state,
        clientSearchTimer: action.payload
      }
    default:
      return {...state}
  }
}