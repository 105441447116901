import React, { useEffect,useState,useRef } from 'react'

import { Row, Col, Button, ButtonGroup } from "shards-react";
import { Stepper,Step } from 'react-form-stepper'
import ReactLoading from 'react-loading'
import Spinner from 'react-bootstrap/Spinner'

import SelectTemplate from './selectTemplate'
import SelectClients from './selectClients'
import ConfirmCustomerDetails from './confirmCustomerDetails'
import CompleteRequest from './completeRequest'
import SigningRequestConfirmation from './requestConfirmation'

import { connect } from 'react-redux'

import { setAuthzTimer,authz } from '../../redux/actions/authActions'
import { sendSigningRequest,setNewRequest, clearError } from '../../redux/actions/newRequestActions'
import { setShowRequest,setActiveStep,setShowConfirmation,setNextButtonTimer } from '../../redux/actions/layoutActions'
import { updateCustomerEmailSuccess } from '../../redux/actions/historyActions'


const mapStateToProps = state => ({
  selectedTemplate: state.newRequest.selectedTemplate,
  selectedClients: state.newRequest.selectedClients,
  newRequest: state.newRequest,
  // auth: state.auth,
  activeStep: state.layout.step,
  step2Valid: state.layout.step2Valid
})

const mapDispatchToProps = dispatch => ({
  sendRequest: (r,a) => dispatch(sendSigningRequest(r,a)),
  setNewRequest: () => dispatch(setNewRequest()),
  clearError: () => dispatch(clearError()),
  setShowRequest: (bool) => dispatch(setShowRequest(bool)),
  setActiveStep: (step) => dispatch(setActiveStep(step)),
  updateHistory: () => dispatch(updateCustomerEmailSuccess()),
  showModalConfirmation: (bool) => dispatch(setShowConfirmation(bool)),
  setNextButtonTimer: (func) => dispatch(setNextButtonTimer(func)),
  setAuthTimer: () => dispatch(setAuthzTimer()),
  authz: () => dispatch(authz())
})

export default connect(mapStateToProps,mapDispatchToProps)(
  ({setShowRequest,setNewRequest,selectedTemplate,selectedClients,newRequest,
    setActiveStep,activeStep,updateHistory,showModalConfirmation,setNextButtonTimer,setAuthTimer,step2Valid}) => {

  // const [activeStep,setActiveStep] = useState(0)
  const [showSpinner,setShowSpinner] = useState(true)
  const [disabledNext,setDisabledNext] = useState(true)
  const [count,setCount] = useState(0)

  const activeStepRef = useRef(activeStep)
  activeStepRef.current = activeStep

  useEffect(() => {
    //TODO: UNSURE WHY THIS WAS NEEDED?
    // if(newRequest.error) {
    //   clearError()
    // } 
    // console.log('update container')
    // authz()
      
    setAuthTimer()

    if(newRequest.finalReport && showSpinner === true) {
      setShowSpinner(false)
    }
    
    checkNextDisabled(activeStep,selectedTemplate,selectedClients,setDisabledNext,step2Valid)


    // return(() => {
    //   setShowSpinner(true)
    // })
  },[activeStep,newRequest.finalReport,disabledNext,selectedTemplate,selectedClients,step2Valid])
  // })
  
  return (

    <div className="h-100">
      <Row  noGutters className="pb-2 pt-0  d-flex justify-content-center"> 
        {/* <Row> */}
          {/* THE STEPPED TEMPLATES WILL LIVE HERE */}

          {/* TEMPLATE LIST */}
          {activeStep === 0 && <SelectTemplate setStep={setActiveStep} />}
          {/* CLIENT LIST */}
          {activeStep === 1 && <SelectClients />}
          {/* CLIENT LIST CONFIRMATION */}
          {activeStep === 2 && <ConfirmCustomerDetails />}
          {/* COMPLETE SIGNING */}
          {activeStep === 3 && <CompleteRequest />}
          {/* SIGNING REQUEST CONFIRMATION */}
          {activeStep === 4 && (!showSpinner ? <SigningRequestConfirmation /> : 
              <Row className="d-flex justify-content-center">
                {/* <ReactLoading type={'spin'} color={'blue'} width={'25%'} height={'25%'} /> */}
                <Spinner animation="border" role="status" style={{color:"#0133b6", width: '10rem', height: '10rem'}} className="ml-3">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Row>
          )}


        {/* </Col> */}
      </Row>
      {/* {(activeStep === 4 && !showSpinner) && 
        <Row noGutters className="py-4 d-flex justify-content-around align-items-center align-self-end">
          <div className="col-lg-8 d-flex justify-content-between">
          <ButtonGroup 
            size="md"
          >
            <Button theme="primary" 
              style={{width: '18vw'}}
              onClick={(e) => {
                setActiveStep(0)
                setNewRequest()
              }}
            >New Signature Request</Button>
          </ButtonGroup>
          <ButtonGroup
            size="md"
          >
            <Button theme="primary" 
              style={{width: '18vw'}}
              onClick={(e) => {
                setActiveStep(0)
                setNewRequest()
                setShowRequest(false)
                updateHistory()
              }}
            >Signature Request History</Button>
          </ButtonGroup>
          </div>
          
        </Row>
      } */}
      {/* <div className="d-flex justify-content-center"> */}
      {activeStep < 4 && <Row  noGutters className="py-2 d-flex justify-content-center align-items-center " style={{width: '95vw'}}>
        {/* <div className="d-flex align-items-center" >
          <ButtonGroup size="md" >
            <Button  theme="primary"
              style={{width: '5vw'}}
              disabled={activeStep === 0}
              onClick={(e) => setActiveStep(activeStep - 1)}
            >Back</Button>
          </ButtonGroup>
        </div> */}
        {/* <div className="d-flex jutify-content-between"> */}
        {/* <div className="d-flex align-items-center" >
          <ButtonGroup size="md" >
            <Button  theme="primary"
              style={{width: '10rem'}}
              disabled={activeStep === 0}
              onClick={(e) => setActiveStep(activeStep - 1)}
            >Back</Button>
          </ButtonGroup>
        </div>  */}

        <Stepper 
        stepClassName="font-color stepper-step"
        // style={{width: '70%'}}
        className="custom-maxwidth py-2"
        styleConfig={{activeBgColor: '#2d9f48', completedBgColor: '#2d9f48', labelFontSize: '18px', fontWeight: 700}}
        connectorStyleConfig={{size: 3, activeColor: '#2d9f48', completedColor: '#2d9f48', disabledColor: '#bdbdbd'}}
        connectorStateColors={true}
        activeStep={activeStep}
        >
          <Step label="SELECT TEMPLATE" />
          <Step label="SELECT CUSTOMER(S)" />
          <Step label="CONFIRM DETAILS" />
          <Step label="COMPLETE REQUEST" />
        </Stepper>

        {/* <div className="d-flex align-items-center pl-2" >
            <ButtonGroup size="md">
              <Button sz="lg" theme="primary"
              style={{width: '10rem'}}
                disabled={disabledNext}
                onClick={(e) => {
                  let timer = setTimeout(() => {
                    if(activeStepRef.current === 3) {
                      //send action
                      showModalConfirmation(true)
                    }else {
                      setActiveStep(activeStepRef.current + 1)
                    }
                  },500)
                  setNextButtonTimer(timer)
                }}
              >{activeStep < 3 ? 'Next' : 'Send'}</Button>
            </ButtonGroup>
          </div> */}
          {/* </div> */}
        {/* <div className="d-flex align-items-center" >
          <ButtonGroup size="md">
            <Button sz="lg" theme="primary"
            style={{width: '5vw'}}
              disabled={disabledNext}
              onClick={(e) => {
                let timer = setTimeout(() => {
                  if(activeStepRef.current === 3) {
                    //send action
                    showModalConfirmation(true)
                  }else {
                    setActiveStep(activeStepRef.current + 1)
                  }
                },200)
                setNextButtonTimer(timer)
              }}
            >{activeStep < 3 ? 'Next' : 'Send'}</Button>
          </ButtonGroup>
        </div> */}
        {/* <Row className="py-4 w-100 justify-content-between"> 
          
          
        </Row> */}
      </Row>}
      {/* </div> */}
      
      {activeStep < 4 && <Row  noGutters className="py-2 pb-4 d-flex justify-content-center align-items-center align-self-end">
        <div className="d-flex align-items-center" >
          <ButtonGroup size="md" >
            <Button  theme="primary"
              style={{width: '10rem'}}
              disabled={activeStep === 0}
              onClick={(e) => setActiveStep(activeStep - 1)}
            >Back</Button>
          </ButtonGroup>
        </div> 
        <div className="d-flex align-items-center pl-2" >
            <ButtonGroup size="md">
              <Button sz="lg" theme="primary"
              style={{width: '10rem'}}
                disabled={disabledNext}
                onClick={(e) => {
                  let timer = setTimeout(() => {
                    if(activeStepRef.current === 3) {
                      //send action
                      showModalConfirmation(true)
                    }else {
                      setActiveStep(activeStepRef.current + 1)
                    }
                  },500)
                  setNextButtonTimer(timer)
                }}
              >{activeStep < 3 ? 'Next' : 'Send'}</Button>
            </ButtonGroup>
          </div>
      </Row>} 
    </div>
  )
})



const checkNextDisabled = (step,template,clients,setDisabledNext,step2Valid) => {
  if(step === 0 && !template) 
    setDisabledNext(true)
  else if(step === 1 && !step2Valid)
  setDisabledNext(true)
  else if(step === 2 && clients) {
    setDisabledNext(checkEmailAndName(clients))
  }else
    setDisabledNext(false)
}

const checkEmailAndName = (_c) => {
  if(_c) {
    for(let k in _c) {
      if(
        (!_c[k].customerEmail || _c[k].customerEmail === null) || 
        (!_c[k].customerName || _c[k].customerName === null) ||
        (_c[k].ccEmail && !_c[k].validCC) || 
        (_c[k].validEmail === false || _c[k].validCC === false)
        ) {
        return true
      }
    }
    return false
  }
}