import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'
import Li from './templateLi'
import Checkbox from './clientLi'
import ReactLoading from 'react-loading'
import ClientsTable from './clientsReactTable'
import ClientsManualTable from './clientsManualTable'
import ClientCheckbox from './clientCheckbox'
import { getClientList, setClientSearchTimer, clearError, setSelectedClients, setClientSearchResults, setClientSearcher, setClientCheckAll, SetClientSearchText } from '../../redux/actions/newRequestActions'
import { setAuthzTimer,authz } from '../../redux/actions/authActions'
import { 
  Card,CardHeader,CardBody,FormInput,Col,Row,Form } from 'shards-react'
import { connect } from 'react-redux'
import FuzzySearch from 'fuzzy-search'
import FlexSearch from 'flexsearch'
import Fuse from 'fuse.js'


const Ul = styled.ul`
  padding: 0;
  list-style-type: none;
  text-align: center;
  font-size: 1.2em;
  max-height: 30vh !important;
  width: 75%;
  overflow-y: auto;
  border: solid 2px #e1e5eb;
  border-radius: .25rem;
`
const _Styled_Div = styled.div`
  /* width: 75%; */
  padding: 0.4375rem 0.75rem;
  cursor: pointer;
`

const Styled_Input = styled.input`
  :hover {
    cursor: pointer;
  }
  margin-right: .2em;
  vertical-align: middle;
`

const mapStateToProps = state => ({
  clients: state.newRequest.clients,
  loading: state.newRequest.loading,
  error: state.newRequest.error,
  selectedClients: state.newRequest.selectedClients,
  searchResults: state.newRequest.clientSearchResults,
  searcher: state.newRequest.clientSearcher,
  checkAll: state.newRequest.checkAll,
  searchText: state.newRequest.clientSearchText
})

const mapDispatchToProps = dispatch => ({
  getClients: () => dispatch(getClientList()),
  clearError: () => dispatch(clearError()),
  setAllClients: (arr) => dispatch(setSelectedClients(arr)),
  setSearchResults: (res) => dispatch(setClientSearchResults(res)),
  // setSearcher: (searcher) => dispatch(setClientSearcher(searcher)),
  setSearchTimer: (func) => dispatch(setClientSearchTimer(func)),
  setCheckAll: (check) => dispatch(setClientCheckAll(check)),
  setSearchText: (text) => dispatch(SetClientSearchText(text)),
  setAuthTimer: () => dispatch(setAuthzTimer())
})

export default connect(mapStateToProps,mapDispatchToProps)
(({setAllClients,getClients,clients,loading,searchResults,checkAll,setSearchResults,setCheckAll,searchText,setSearchText,error,setAuthTimer,setSearchTimer,selectedClients}) => {

  const [searcher,setSearcher] = useState(false)

  useEffect(() => {
    // console.log('re-render selectClients')
    setAuthTimer()
    // console.log('clients: ', clients, ' loading: ', loading, ' error: ', error)
    if(!clients && !loading && !error) {
      // console.log('getting clients')
      getClients()
    }
    // if(!searcher && clients) {
    //   setSearcher(new FuzzySearch(
    //     clients,
    //     ["name"],
    //     {caseSensitive:false,sort:true}
    //   ))
    // }
    if(!searcher && clients) {
      // setSearcher(new Fuse(clients, {
      //   shouldSort: true,
      //   threshold: 0.2,
      //   location: 0,
      //   distance: 100,
      //   minMatchCharLength: 4,
      //   includeScore: true,
      //   includeMatches: true,
      //   keys: ["name","customerName","customerEmail"]
      // }))
      setSearcher(new FuzzySearch(clients, ["name","customerName","customerEmail"], {
        caseSensitive:false,
        sort: true
      }))
      // setSearcher(new FlexSearch('score'))
    }

    // return (() => {
    //   let arr = []
    //   for(let i in clients) {
    //     if(clients[i].checked) {
    //       arr.push(clients[i])
    //     }
    //   }
    //   setAllClients(arr)
    // })
    
    // if(searchResults && searchResults.length < 20) console.log('searchText: ', searchText, ' searchResults: ', searchResults)

  },[clients,searchResults,loading])

{/* 
  1. pass searcher into react table
  2. place select/deselect all checkbox into header of react table card
  2. create new checkbox to mimic old one without displaying company name
  3. create sticky header, scrollable table body
  4. verify filtering still works as expected.
*/}

  const searchUtil = {
    searcher: searcher,
    val: searchText,
    setVal: setSearchText,
    setSearchResults: setSearchResults,
    setTimer: setSearchTimer
  }

  const checkAllUtil = {
    setAllClients: setAllClients,
    setCheckAll: setCheckAll,
    checkAll: checkAll


  }


  


  let data
  let _data = ((searchResults && (searchText && searchText.length > 0)) && searchResults) || ((clients && clients.length > 0) && clients)
  if(_data) {
    _data = _data.sort((a,b) => (a.item ? a.item.name.localeCompare(b.item ? b.item.name : b.name) : a.name.localeCompare(b.item ? b.item.name : b.name)))
    _data = _data.sort((a,b) => {
      if(a.item) {
        if(a.item.name) {
          if(b.item) {
            if(b.item.name) {
              return 0
            }else {
              return -1
            }
          }else {
            if(b.name) {
              return 0
            }else {
              return -1
            }
          }
        }else {
          if(b.item) {
            if(b.item.name) {
              return 1
            }else {
              return 0
            }
          }else {
            if(b.name) {
              return 1
            }else {
              return 0
            }
          }
        }
      }else {
        if(a.name) {
          if(b.item) {
            if(b.item.name) {
              return 0
            }else {
              return -1
            }
          }else {
            if(b.name) {
              return 0
            }else {
              return -1
            }
          }
        }else {
          if(b.item) {
            if(b.item.name) {
              return 1
            }else {
              return 0
            }
          }else {
            if(b.name) {
              return 1
            }else {
              return 0
            }
          }
        }
      }
    })
    data = _data.sort((a,b) => (a.checked === b.checked ? 0 : a.checked ? -1 : 1))
  }

  // (a.item ? a.item.name.localeCompare(b.item ? b.item.name : b.name) : a.name.localeCompare(b.item ? b.item.name : b.name))





  const columns = [{
    Header: props => <span style={{fontSize: '16px', fontWeight: '700'}}></span>,
    accessor: 'checkbox',
    Cell: ({row}) => (<div className="d-flex justify-content-center align-items-center" style={{height: '100%'}}>
      <ClientCheckbox disabled={checkAll} checkAll={checkAll} client={row.original} />
    </div>)
  }, 
  {
    Header: props => <span style={{fontSize: '16px', fontWeight: '700'}}>Customer Company</span>,
    accessor: 'name',
    Cell: ({row}) => ( <div className="d-flex justify-content-start align-items-center" style={{height: '100%'}}>
        <span style={{fontSize: '16px'}}>{row.original.item ? row.original.item.name : row.original.name}</span>
      </div>)
  },
  {
    Header: props => <span style={{fontSize: '16px', fontWeight: '700'}}>Customer Name</span>,
    accessor: 'customerName',
    Cell: ({row}) => ( <div className="d-flex justify-content-start align-items-center" style={{height: '100%'}}>
        <span style={{fontSize: '16px'}}>{row.original.item ? row.original.item.customerName : row.original.customerName}</span>
      </div>)
  },
  {
    Header: props => <span style={{fontSize: '16px', fontWeight: '700'}}>Customer Email</span>,
    accessor: 'customerEmail',
    Cell: ({row}) => (<div className="d-flex justify-content-start align-items-center" style={{height: '100%'}}>
        <span style={{fontSize: '16px'}}>{row.original.item ? row.original.item.customerEmail : row.original.customerEmail}</span>
      </div>)
  }]
  
  return (
  <div>
    <Card className="d-flex align-items-stretch" style={{width: '95vw'}}>
      <CardHeader className="d-flex justify-content-center align-items-center pb-2">
        <div className="ml-3" style={{width: '1.5rem', height: '1.5rem'}} ></div>
        <h4 className="m-0">Select Customer for Signature Request</h4>
        {loading ?
        //  <ReactLoading className="d-flex align-items-center p-0 pl-3" type={'spin'} color={'#0133b6'} width={'2.3rem'} height={'2.3rem'} />
        <Spinner animation="border" role="status" style={{color:"#0133b6", width: '1.5rem', height: '1.5rem'}} className="ml-3">
          <span className="sr-only">Loading...</span>
        </Spinner>
        :
          <div className="ml-3" style={{width: '1.5rem', height: '1.5rem'}} ></div>  
        }
      </CardHeader>
      <CardBody className="d-flex justify-content-center" >
        {/* {clients && <ClientsTable 
          data={data}
          columns={columns}
          searchUtil={searchUtil}
          checkAllUtil={checkAllUtil}
          clients={clients}
        />} */}
        {data && <ClientsManualTable data={data} searcher={searcher} />}
        {/* {!clients && <Card
          style={{height: '5vh'}}>
          </Card>} */}
      </CardBody>
    </Card>
  </div>
)})

const templates = [
  "Template 1","Template 2","Template 3","Template 4","Template 5","Template 6","Template 7","Template 8","Template 9"
]


//OLD SELECT CLIENT PAGE CONTENT
// {<Col lg="10">
//           <Card
//             style={{
//               'boxShadow':'none',
//               border: '.15rem solid #e1e5eb',
//               minHeight: '50vh',
//               maxHeight: '50vh'
//             }}
//           >
//             <CardHeader>
//               <Form className="d-flex justify-content-center">
                
//                 <FormInput 
//                 disabled={checkAll}
//                 placeholder="Search Customers..."
//                 className="w-75 form-control"
//                 style={{fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
//                 defaultValue={searchText}
//                 onKeyPress={(e) => {
//                   if(e.charCode === 13) e.preventDefault()
//                 }}
//                 onChange={(e) => {
//                   const val = e.target.value
//                   setSearchText(val)
//                   setSearchResults(searcher.search(val))
//                 }}
//                 />
//               </Form>
//               <Row className="d-flex justify-content-center">
//                 <div className="d-flex align-items-start w-75">
//                   <_Styled_Div 
//                   style={{fontSize: '1.2em'}}
//                   className="d-flex align-items-center pt-2"
//                   onClick={()=>{
//                     if(!checkAll) {
//                       //mark all as selected, add all
//                       setAllClients(searchResults || clients)
//                       setCheckAll(!checkAll)
//                     }else {
//                       setAllClients(null)
//                       setCheckAll(!checkAll)
//                     }
//                   }}
//                   >
//                     <Input
//                       type="checkbox"
//                       checked={checkAll}
//                     />Select All/ Unselect All</_Styled_Div>
//                 </div>
                
//               </Row>
//             </CardHeader>
//             <CardBody className="p-0">
//               <Row className="justify-content-center">
//                 {/* list */}
//                 <Ul>
//                   {((searchResults && searchResults.length > 0) && searchResults.map((c,i) => (
//                     // myKey is used to access 'key', selectedKey is used to track the active selected template,
//                     // handler is used to update to the neweset selected template.
//                     <Checkbox disabled={checkAll} results={searchResults} key={i} client={c.item ? c.item : c}/>
//                   )))
//                   ||
//                   ((clients && clients.length > 0) && clients.map((c,i) => (
//                     // myKey is used to access 'key', selectedKey is used to track the active selected template,
//                     // handler is used to update to the neweset selected template.
//                     <Checkbox disabled={checkAll}  key={i} client={c}/>
//                   )))}
//                 </Ul>
//               </Row>
//             </CardBody>
//           </Card>
//           </Col>}

