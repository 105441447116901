import React, { useState,useEffect } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { setSelectedTemplate,updateRequest } from '../../redux/actions/newRequestActions'

// const Li = styled.li`
//   :hover {
//     cursor: pointer;
//     color: ${props => props.selected ? 'white' : '#0133b6'};
//   };
//   background-color: ${props => props.selected && '#0133b6'};
//   color: ${props => props.selected && 'white'};
// ` 

// const Span = styled.span`
//   width: 100%;
//   padding-left: 2em;
// `

const Li = styled.li`
  cursor: ${props => props.disabled ? 'not-allowed' : 'inherit'};
  opacity: ${props => props.disabled ? '0.6' : '1'};
  :hover {
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    opacity: ${props => props.disabled ? '0.6' : '1'};
    color: #0133b6;
    overflow: hidden;
    padding-left: none;
    /* width: 75%; */
  }
` 

const Input = styled.input`
  :hover {
    cursor: pointer;
  }
  margin-right: .2em;
  vertical-align: middle;
`

const Span = styled.span`
  width: 100%;
  padding-left: .5em;
`

const mapStateToProps = state => ({
  selectedTemplate: state.newRequest.selectedTemplate
})

const mapDispatchToProps = dispatch => ({
  setSelectedTemplate: (t) => dispatch(setSelectedTemplate(t)),
  updateRequest: (req) => dispatch(updateRequest(req))
})

export default connect(mapStateToProps,mapDispatchToProps)((props) => {

  // holds the selected flag for setting this one as active
  const [selected,setSelected] = useState(false)
 
  // console.log('props: ', props)
  useEffect(() => {
    if(props.selectedTemplate ? props.template.template_id === props.selectedTemplate.template_id : false) {
      setSelected(true)
    }else {
      setSelected(false)
    }
  },[selected,props.selectedTemplate])

  // return (
  //   <Li
  //     className="d-flex justify-content-center"
  //     selected={selected}
  //     onClick={(e) => {
  //       if(!selected) {
  //         props.setSelectedTemplate(props.template)
  //         setSelected(!selected)
  //         props.handler(props.myKey)
  //       }else {
  //         props.setSelectedTemplate(undefined)
  //         setSelected(!selected)
  //         // props.handler(null)
  //       }
  //     }}
  //     // PROGRESS FORM TO NEXT STAGE
  //     onDoubleClick={(e) => {
  //       props.setSelectedTemplate(props.template)
  //       props.setStep(1)
  //     }}
  //   ><Span
  //     className="d-flex align-items-center justify-content-start"
  //   >{props.template.title}</Span></Li>
  // )

  return (
    <Li
      className="d-flex justify-content-center"
      onClick={(e) => {
        if(!selected) {
          props.setSelectedTemplate(props.template)
          setSelected(!selected)
          props.handler(props.myKey)
        }else {
          props.setSelectedTemplate(undefined)
          setSelected(!selected)
          // props.handler(null)
        }
        //this will remove the pre-built request if they go to the complete 
        //stage but instead needed to change templates
        props.updateRequest(undefined)
      }}
    >
      <Span className="d-flex align-items-center justify-content-start">
        <Input
          type="radio"
          checked={selected}
        />
        {props.template.title}</Span>
    </Li>
  )
}) 

