import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ReactLoading from 'react-loading'
import { getTemplateList } from '../../redux/actions/newRequestActions'
import { setAuthzTimer,authz } from '../../redux/actions/authActions'
import Spinner from 'react-bootstrap/Spinner'

import Li from './templateLi'

import { 
  Card,CardHeader,CardBody,Form,FormInput,Col,Row,FormRadio } from 'shards-react'
import { connect } from 'react-redux'
import FuzzySearch from 'fuzzy-search'


const Ul = styled.ul`
  padding: 0;
  /* margin: 5px 5px 5px 5px; */
  list-style-type: none;
  text-align: center;
  font-size: 1.2em;
  min-height: 10vh !important;
  width: 100%;
  overflow-y: auto;
  border: solid 2px #e1e5eb;
  border-radius: .25rem;
`

export const mapStateToProps = state => ({
  loading: state.newRequest.loading,
  error: state.newRequest.error,
  templates: state.newRequest.templates,
  selectedTemplate: state.newRequest.selectedTemplate
})

export const mapDispatchToProps = dispatch => ({
  getTemplates: () => dispatch(getTemplateList()),
  setAuthTimer: () => dispatch(setAuthzTimer())
})

export default connect(mapStateToProps,mapDispatchToProps)(({loading,error,templates,getTemplates,setStep,selectedTemplate,setAuthTimer}) => {

  const [selectedKey,setSelectedKey] = useState(undefined)
  const [searchResults,setSearchResults] = useState()
  const [searcher,setSearcher] = useState()

  useEffect(() => {
    setAuthTimer()
    if(!templates ? !loading ? !error : false : false) {
      getTemplates()
    } 
    if(!selectedKey && selectedTemplate) {
      setSelectedKey(selectedTemplate.Id)
    }
    if(templates && !searcher) {
      setSearcher(new FuzzySearch(
        templates,
        ["title"],
        {caseSensitive:false}
      ))
    }
  },[templates,searchResults,selectedKey])
  
  
 
  return (
  <div>
    <Card className="d-flex" style={{width: '95vw'}}>
      <CardHeader className="d-flex justify-content-center align-items-center pb-2">
        <div className="ml-3" style={{width: '1.5rem', height: '1.5rem'}} ></div>
        <h4 className="m-0">Select Template for Signature Request</h4>
        {loading ?
        //  <ReactLoading className="d-flex align-items-center p-0 pl-3" type={'spin'} color={'#0133b6'} width={'2.3rem'} height={'2.3rem'} />
        <Spinner animation="border" role="status" style={{color:"#0133b6", width: '1.5rem', height: '1.5rem'}} className="ml-3">
          <span className="sr-only">Loading...</span>
        </Spinner>
        :
          <div className="ml-3" style={{width: '1.5rem', height: '1.5rem'}} ></div>  
        }
      </CardHeader>
      <CardBody className="d-flex justify-content-center">
        {/* <Col lg="12"> */}
          <Card
            style={{
              'boxShadow':'none',
              border: '.15rem solid #e1e5eb',
              minHeight: '50vh'
            }}
            className="p-0 w-100"
          >
            {/* <CardHeader>
              <Form className="d-flex justify-content-center">
                {/* TODO:: IMPLEMENT FIX FOR SEARCHER HERE */}
                {/* <FormInput 
                placeholder="Search Templates..."
                className="w-75"
                onChange={(e) => {
                  setSearchResults(searcher.search(e.target.value))
                }}
                /> 
              </Form>
            </CardHeader> */}
            <CardBody className="p-0 d-flex justify-content-center">
              {/* <Row className="justify-content-center"> */}
                {/* list */}
                <Ul className="ml-3 mr-3 mt-3">
                  {(searchResults && searchResults.map((t,i) => (
                    // myKey is used to access 'key', selectedKey is used to track the active selected template,
                    // handler is used to update to the neweset selected template.
                    <Li template={t} key={i} myKey={t.template_id} selectedKey={selectedKey} handler={setSelectedKey} setStep={setStep} />
                  )))
                  ||
                  (templates && templates.map((t,i) => (
                    // myKey is used to access 'key', selectedKey is used to track the active selected template,
                    // handler is used to update to the neweset selected template.
                    <Li template={t} key={i} myKey={t.template_id} selectedKey={selectedKey} handler={setSelectedKey} setStep={setStep} />
                  )))}
                </Ul>
              {/* </Row> */}
            </CardBody>
          </Card>
        {/* </Col> */}
      </CardBody>
    </Card>
  </div>
)})

const testtemplates = [
  {Id: 1, title:"template 1"},
  {Id: 2, title:"template 2 dsaf fdsa "},
  {Id: 3, title:"template 3"},
  {Id: 4, title:"template 4"},
  {Id: 5, title:"template 5 adsf "},
  {Id: 6, title:"template 6"},
  {Id: 7, title:"template 7 asdf asfd a fds"},
  {Id: 8, title:"template 8"},
  {Id: 9, title:"template 9"},
  {Id: 10, title:"template 10"},

]