import React, { useState,useEffect } from 'react'
import styled from 'styled-components'
import { useTable, usePagination } from 'react-table'

import { Card,CardHeader,CardBody,CardFooter,Button,Col,FormSelect,Row,Container} from 'shards-react'



export default({columns,data,index,setPageIndex}) => {

  const [num_pages,setNumPages] = useState(0)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    pageOptions,
    pageCount,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize},
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: (index || 0), pageSize: 5},
  },
  usePagination)

  // useEffect(() => {
  //   setNumPages(Math.ceil(data.length / pageSize))
  // },[pageSize,pageIndex,data])
  
  // useEffect(() => {
  //   console.log('setPageIndex: ', setPageIndex)
  // })
  

  return (
    
    <Card
    style={{
      'boxShadow':'none',
      border: '.15rem solid #e1e5eb',
    }}
    className="p-0">
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageCount,
              pageOptions,
            },
            null,
            2
          )}
        </code>
      </pre> */}
      <CardHeader className="p-0">
        <Container fluid className="file-manager__filters border-bottom">
          <Row>
            <Col className="file-manager__filters__rows d-flex justify-content-start" md="10">
            
            </Col>
            <Col className="file-manager__filters__rows d-flex justify-content-end" md="2">
              <div className="d-flex align-items-center">
              <span  style={{fontSize: '16px', fontWeight: '700', color: '#212529'}} className="m-0 mr-3">Show</span>
              </div>
              <FormSelect
              className="form-control"
              //'minHeight':'3.5rem','marginTop':'0px','bordeRadius':'.275rem',
              style={{'minHeight':'2.5rem','marginTop':'0px','bordeRadius':'.275rem', fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
                size="sm"
                onChange={(e) => {
                  setPageSize(Number(e.target.children[e.target.selectedIndex].value))
                  gotoPage(0)
                  setPageIndex(0)
                }}
              >
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
              </FormSelect>
            </Col>
          </Row>
        </Container>
      </CardHeader>
      <CardBody className="p-0">
        <table style={{height: '100%'}} className="table table-bordered mb-0" {...getTableProps()} >
          <thead className=""> 
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th style={{textAlign: 'left'}} {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody style={{height: '100%'}} {...getTableBodyProps()} >
            {page.map(
              (row, i) => {
                prepareRow(row)
                return (
                  <tr style={{height: '100%'}} {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td style={{height: '100%'}} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )}
            )}
          </tbody>
        </table>
      </CardBody>
      {data.length > pageSize && <CardFooter className="p-0">
        <Container fluid className="file-manager__filters border-top">
          <Row>
            {/* previous button */}
          <Col className="file-manager__filters__rows d-flex" md="4">
              <Button 
              disabled={!canPreviousPage} 
              theme="primary"
              style={{width: '10rem'}}
              onClick={(e) => {
                previousPage()
                setPageIndex(pageIndex-1)
              }}
              >Previous</Button>
          </Col>
          {/* select page */}
          <Col className="file-manager__filters__rows d-flex justify-content-center" md="4">
            <div className="d-flex align-items-center">
              <span style={{fontSize: '16px', fontWeight: '700', color: '#212529'}}>Page</span>
              <FormSelect 
                style={{'minHeight':'2.5rem','marginTop':'0px','bordeRadius':'.275rem', fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}
                disabled={pageOptions.length === 0}
                value={pageIndex+1}
                onChange={(e) => {
                  gotoPage(Number(e.target.children[e.target.selectedIndex].value - 1))
                  setPageIndex(Number(e.target.children[e.target.selectedIndex].value - 1))
                }}
              >
                {pageOptions.length > 0 && pageOptions.map((num, i) => (
                  <option key={i}>{num + 1}</option>
                ))}
              </FormSelect>
              <span style={{fontSize: '16px', fontWeight: '700', color: '#212529'}}>of {pageOptions.length}</span>
            </div>
              
          </Col>
          {/* next button */}
          <Col className="file-manager__filters__rows d-flex justify-content-end" md="4">
            <Button 
            theme="primary"
            style={{width: '10rem'}}
            disabled={!canNextPage}
            onClick={(e) => {
              nextPage()
              setPageIndex(pageIndex+1)
            }}
            >Next</Button>
          </Col>
          </Row>
        </Container>
      </CardFooter>}
    </Card>
  )
}

const buildPageOptions = (length) => {
  let i = 1
  let arr = []
  while(i <= length) {
    arr.push(i)
    i++
  }
  return arr
}