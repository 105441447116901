import React, { useEffect,useState } from "react";
import { Container, Row, Col, Button, ButtonGroup } from "shards-react";
import { NavLink } from "react-router-dom";
import ToastError from '../components/common/toastError'
import { Alert } from 'shards-react'

import { connect } from 'react-redux'

import { setShowRequest,setActiveStep } from '../redux/actions/layoutActions'
import { updateCustomerEmailSuccess, updateSignatureListPrams } from '../redux/actions/historyActions'
import { setNewRequest } from '../redux/actions/newRequestActions'
import { setAuthzTimer } from "../redux/actions/authActions"

import PageTitle from "../components/common/PageTitle";
import NewRequest from '../components/new-request-form/newRequestContainer'
import ViewHistory from '../components/history-table/viewHistoryContainer'


const mapStateToProps = state => ({
  showRequest: state.layout.showRequest,
  requestError: state.newRequest.error,
  historyError: state.history.error,
  activeStep: state.layout.step,
  params: state.history.params
})

const mapDispatchToProps = dispatch => ({
  setShowRequest: (bool) => dispatch(setShowRequest(bool)),
  updateHistory: () => dispatch(updateCustomerEmailSuccess()),
  setActiveStep: (step) => dispatch(setActiveStep(step)),
  setNewRequest: () => dispatch(setNewRequest()),
  setAuthzTimer: () => dispatch(setAuthzTimer()),
  resetSigListParams: (params) => dispatch(updateSignatureListPrams(params))
})

export default connect(mapStateToProps,mapDispatchToProps)
(({activeStep,showRequest,setShowRequest,requestError,historyError,updateHistory,setActiveStep,setNewRequest,resetSigListParams,params}) => {

  const [showError,setShowError] = useState(false)
  let error = (showRequest ? requestError : historyError)
  // let error = "This is a test of the error bar"

  useEffect(() => {
    // setAuthzTimer()
    if(error && !showError) {
      setShowError(true)
    }else {
      setShowError(false)
    }
    error = (showRequest ? requestError : historyError)
    // error = "This is a test of the error bar"
  },[requestError,historyError,showRequest])
  
  return (
  <Container fluid className="main-content-container px-4">

    {showError && <ToastError error={error} set={setShowError}/>}

    <Row noGutters className="py-4  d-flex justify-content-center">
      <Col  className="col d-flex align-items-center">
        <ButtonGroup size="md" className="d-inline-flex mb-3 mb-sm-0 mx-auto">
          <Button theme={showRequest ? "secondary" : "primary"} 
            onClick={(e) => {
              if(activeStep === 4) {
                setActiveStep(0)
                setNewRequest()
              }  
              setShowRequest(true)
              resetSigListParams({page_size: 5})
            }}
            className={showRequest ? "actv" : ""}
            style={{width: '18vw'}}
          >
            New Signature Request
          </Button>
          <Button theme={!showRequest ? "secondary" : "primary"}
            onClick={(e) => {
              if(showRequest) {
                updateHistory()
              }
              setShowRequest(false)
            }}
            style={{width: '18vw'}}
            className={showRequest ? "" : "actv"}
          >
            History
          </Button>
        </ButtonGroup>
      </Col>

    </Row>

    {/* NEW SIGNING REQUEST FORM */}
    {showRequest && <NewRequest />}

    {/* HISTORY TABLE */}
    {!showRequest && <ViewHistory />}

  </Container>
)})

